<template>
  <div>
    <div class="sub-page-header">
      <div class="container-fluid py-4 d-flex justify-content-between">
        <h3 class="page-title sm">Remove stock items</h3>
      </div>
    </div>
    <div class="container-fluid " style="margin-top: -4.5rem;">
      <b-row>
        <b-col cols="12">
          <b-card class="p-2 px-3">
            <b-form @submit.prevent="removeStock" ref="form">
              <div class="d-flex justify-content-between">
                <b-form-group label="Item type" class="w-100"
                              :state="itemTypeState"
                              invalid-feedback="Enter an item type.">
                  <SingleSearchSelect
                      placeholder="Select an item type"
                      :provider="searchItemType" ref="itemTypeSearch"
                      @itemChanged="itemChanged"></SingleSearchSelect>
                </b-form-group>
                <b-button style="height: 38px; margin-top: 31px" class="ml-2" @click="openQRScanner()"
                          variant="light">
                  <b-icon icon="upc-scan"></b-icon>
                </b-button>
              </div>
              <b-form-group label="Location"
                            :state="locationState"
                            invalid-feedback="Enter a location.">
                <SingleSearchSelect
                    placeholder="Select a location"
                    :provider="searchLocation" ref="locationSearch"
                    @itemChanged="locationChanged"></SingleSearchSelect>
                <template v-if="quantityAtLocationFetched">
                  <small class="text-muted">
                    Found {{ quantityAtLocation.amount }} of type <em>{{ itemType.name }}</em>, of which
                    {{ quantityAtLocation.amountReserved }} are reserved.
                  </small><br>
                  <small class="text-muted">
                    Amount that can be removed: {{ quantityAtLocation.amount - quantityAtLocation.amountReserved }}
                  </small>
                </template>
              </b-form-group>

              <b-form-group label="Quantity" :disabled="!quantityAtLocationFetched"
                            :state="this.addAttempt ? this.quantity > 0 : null"
                            invalid-feedback="Quantity is required, and should be a number higher than 0.">
                <b-form-input v-model="quantity" type="number" min="0"
                              :max="quantityAtLocation.amount - quantityAtLocation.amountReserved"></b-form-input>
              </b-form-group>

              <b-button :disabled="quantity < 1" variant="success"
                        style="width: 100%" type="submit">
                Remove items from stock
              </b-button>
            </b-form>
            <b-overlay :show="showOverlay" no-wrap>
            </b-overlay>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <QRCodeScannerModal ref="qrCodeScanner"></QRCodeScannerModal>
  </div>
</template>

<script>
import SingleSearchSelect from "@/components/util/SingleSearchSelect";
import QRCodeScannerModal from "@/components/routes/warehouse/QRCodeScannerModal";


export default {
  name: "RemoveItems",
  components: {QRCodeScannerModal, SingleSearchSelect},
  data() {
    return {
      showOverlay: false,
      itemsToBeRemoved: [],
      itemType: null,
      location: null,
      quantityAtLocation: 0,
      quantityAtLocationFetched: false,
      quantity: 0,
      max: 0,
      current: 0,
      addAttempt: false
    }
  },
  methods: {
    getAvailableQuantityIfReady() {
      if (this.location !== null && this.itemType !== null) {
        this.$maxios('get',
            `stockItems/quantity?itemTypeId=${this.itemType.id}&stockLocationId=${this.location.id}`)
            .then(response => {
              console.log(response.data)
              this.quantityAtLocation = response.data
              this.quantityAtLocationFetched = true
            })
      }
    },
    searchItemType(query, callback) {
      this.$maxios('get', `itemTypes?q=${query}`).then(response => {
        const items = response.data.content.map(i => {
          return {value: i, text: i.name}
        })
        callback(items)
      }).catch(() => {
        this.$alerts.setAlertMessage('Something went wrong searching for item types.', 10, 'danger')
        callback([])
      })
    },
    openQRScanner() {
      this.$refs.qrCodeScanner.attemptScan((itemType) => {
        this.itemType = itemType
        this.$refs.itemTypeSearch.setItemTextFromOutside(itemType.name)
        this.getAvailableQuantityIfReady()
      })
    },
    searchLocation(query, callback) {
      this.$maxios('get', `stockLocations?q=${query}`).then(response => {
        const items = response.data.content.map(i => {
          return {value: i, text: i.name}
        })
        callback(items)
      }).catch(() => {
        this.$alerts.setAlertMessage('Something went wrong searching for stock locations.', 10, 'danger')
        callback([])
      })
    },
    itemChanged(item) {
      this.itemType = item
      this.getAvailableQuantityIfReady()
    },
    locationChanged(location) {
      this.location = location
      this.getAvailableQuantityIfReady()
    },
    removeStock(event) {
      event.preventDefault()
      console.log('remove')
      this.addAttempt = true
      if (this.$refs.form.checkValidity() === true && this.itemTypeState && this.locationState) {
        this.$maxios('delete',
            `stockItems/bulk?itemTypeId=${this.itemType.id}&stockLocationId=${this.location.id}&count=${this.quantity}`
        ).then(() => {
          this.$router.push({ name: 'warehouse' })
          // this.addAttempt = false
          // this.itemType = null
          // this.location = null
          // this.quantity = 1
          // this.quantityAtLocationFetched = false
          // this.quantityAtLocation = null
          // this.$refs.itemTypeSearch.reset()
          // this.$refs.locationSearch.reset()
        }).catch(() => {
          this.$alerts.setAlertMessage(
              "Something went wrong trying to remove the items from stock.",
              2,
              'danger'
            )
        })

      }
    }
  },
  computed: {
    locationState: function () {
      return this.addAttempt ? this.location !== null : null
    },
    itemTypeState: function () {
      return this.addAttempt ? this.itemType !== null : null
    }
  }
}
</script>

<style scoped>

</style>