<template>
  <div>
    <div class="sub-page-header">
      <div class="container-fluid py-4 d-flex justify-content-between">
        <h3 class="page-title sm">Register stock items</h3>
      </div>
    </div>
    <div class="container-fluid " style="margin-top: -4.5rem;">
      <b-row>
        <b-col cols="8">
          <b-card class="p-2 px-3">
            <b-form @submit.prevent="addItemsToList()" ref="form">
              <div class="d-flex justify-content-between">
                <b-form-group label="Item type" class="w-100"
                              :state="itemTypeState"
                              invalid-feedback="Enter an item type.">
                  <SingleSearchSelect
                      placeholder="Select an item type"
                      :provider="searchItemType" ref="itemTypeSearch"
                      @itemChanged="itemChanged"></SingleSearchSelect>
                </b-form-group>
                <b-button style="height: 38px; margin-top: 31px" class="ml-2" @click="openQRScanner()"
                          variant="light"><b-icon icon="upc-scan"></b-icon></b-button>
              </div>
              <b-form-group label="Location"
                            :state="locationState"
                            invalid-feedback="Enter a location.">
                <SingleSearchSelect
                    placeholder="Select a location"
                    :provider="searchLocation" ref="locationSearch"
                    @itemChanged="locationChanged"></SingleSearchSelect>
              </b-form-group>
              <b-form-group label="Quantity"
                            :state="this.addAttempt ? this.quantity > 0 : null"
                            invalid-feedback="Quantity is required, and should be a number higher than 0.">
                <b-form-input v-model="quantity" type="number"></b-form-input>
              </b-form-group>
              <b-button type="submit" variant="success" style="width: 100%">
                Add items to list
              </b-button>
            </b-form>
            <b-overlay :show="showOverlay" no-wrap>
            </b-overlay>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card style="min-height: 100px">
            <template #header>
              <span>Stock to be added</span>
            </template>
            <div class="p-2 px-3">
              <div v-for="(location, name) of locations" :key="name">
                <div><strong>{{ name }}</strong></div>
                <ul>
                  <li v-for="(item, index) of location" :key="index">
                    {{ item.quantity }}x {{ item.itemType.name }}
                  </li>
                </ul>
              </div>
              <b-button :disabled="itemsToBeAdded.length === 0" variant="success"
                        style="width: 100%" @click="addStock">
                Add items to stock
              </b-button>
            </div>
            <b-overlay :show="showOverlay" no-wrap>
            </b-overlay>
          </b-card>
          <div class="mt-3" v-if="max > 0">
            <h6>Registering stock items.</h6>
            <b-progress :max="max">
              <b-progress-bar :value="current" :label="`${((current / max) * 100).toFixed(0)}%`"></b-progress-bar>
            </b-progress>
          </div>
        </b-col>
      </b-row>

    </div>
    <QRCodeScannerModal ref="qrCodeScanner"></QRCodeScannerModal>
  </div>
</template>

<script>
import SingleSearchSelect from "@/components/util/SingleSearchSelect";
import QRCodeScannerModal from "@/components/routes/warehouse/QRCodeScannerModal";


export default {
  name: "AddItems",
  components: {QRCodeScannerModal, SingleSearchSelect},
  data() {
    return {
      showOverlay: false,
      itemsToBeAdded: [],
      locations: {},
      itemType: null,
      location: null,
      quantity: 1,
      addAttempt: false,
      max: 0,
      current: 0
    }
  },
  methods: {
    searchItemType(query, callback) {
      this.$maxios('get', `itemTypes?q=${query}`).then(response => {
        const items = response.data.content.map(i => {
          return {value: i, text: i.name}
        })
        callback(items)
      }).catch(() => {
        this.$alerts.setAlertMessage('Something went wrong searching for item types.', 10, 'danger')
        callback([])
      })
    },
    openQRScanner() {
      this.$refs.qrCodeScanner.attemptScan((itemType) => {
        this.itemType = itemType
        this.$refs.itemTypeSearch.setItemTextFromOutside(itemType.name)
      })
    },
    searchLocation(query, callback) {
      this.$maxios('get', `stockLocations?q=${query}`).then(response => {
        const items = response.data.content.map(i => {
          return {value: i, text: i.name}
        })
        callback(items)
      }).catch(() => {
        this.$alerts.setAlertMessage('Something went wrong searching for stock locations.', 10, 'danger')
        callback([])
      })
    },
    itemChanged(item) {
      this.itemType = item
    },
    locationChanged(location) {
      this.location = location
    },
    async addStock() {
      this.showOverlay = true
      let max = 0
      for (const item of this.itemsToBeAdded) {
        max += parseInt(item.quantity)
      }
      this.max = max
      for (const item of this.itemsToBeAdded) {
        for (let i = 0; i < item.quantity; i++) {
          await this.$maxios('post', 'stockItems', {
            itemTypeId: item.itemType.id,
            stockLocationId: item.location.id
          }).catch(() => {
          })
          this.current += 1
        }
      }
      this.showOverlay = false
    },
    addItemsToList() {
      this.addAttempt = true
      if (this.$refs.form.checkValidity() === true && this.itemTypeState && this.locationState) {
        if (!this.locations[this.location.name]) {
          this.locations[this.location.name] = []
        }
        const obj = {
          itemType: this.itemType,
          location: this.location,
          quantity: this.quantity
        }
        this.locations[this.location.name].push(obj)
        this.itemsToBeAdded.push(obj)

        this.addAttempt = false
        this.itemType = null
        this.location = null
        this.quantity = 1
        this.$refs.itemTypeSearch.reset()
        this.$refs.locationSearch.reset()
      }
    }
  },
  computed: {
    locationState: function () {
      return this.addAttempt ? this.location !== null : null
    },
    itemTypeState: function () {
      return this.addAttempt ? this.itemType !== null : null
    }
  }
}
</script>

<style scoped>

</style>