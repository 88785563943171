<template>
  <div v-if="itemType">
    <div class="sub-page-header">
      <div class="container-fluid py-4 d-flex justify-content-between">
        <h3 class="page-title">Warehouse for {{itemType.name}}</h3>
      </div>
    </div>
    <div class="container-fluid" style="margin-top: -4.5rem;">
      <b-row>
        <b-col>
          <Table
              title="Stock Overview"
              small
              :page-options="[10, 20, 50, 100]"
              api-url="/stockItems"
              :fields="fields"
              :filter-search-provider="searchLocation"
              per-page-customizable
              filterable
              overview-link-key="id"
              delete-info-key="itemType.name"
              deletable
              @row-clicked="rowClicked"
              filter-query-param-name="locationId"
              :static-query-params="'itemTypeId=' + itemType.id"
          ></Table>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import '@/assets/styles/layout.css'
import Table from "@/components/routes/Table"

export default {
  name: "ItemTypeSpecificWarehouse",
  components: {Table},
  data: function () {
    return {
      fields: [
        {
          key: 'itemType.name',
          label: 'Item Type',
          sortable: false
        },
        {
          key: 'stockLocation.name',
          sortable: false,
          label: 'Location'
        },
        {
          key: 'reservingProcessId',
          sortable: false,
          label: 'Reserving id',
          formatter: (item) => item ? item : '-'
        },
        'options'
      ],
      itemType: null
    }
  },
  methods: {
    searchLocation(query, callback) {
      this.$maxios('get', `stockLocations?&q=${query}`).then(response => {
        const items = response.data.content.map(i => {
          return {value: i.id, text: i.name}
        })
        callback(items)
      }).catch(() => {
        this.$alerts.setAlertMessage('Something went wrong searching for locations.', 10, 'danger')
        callback([])
      })
    },
    rowClicked(row) {
      console.log(row)
    }
  },
  mounted() {
    this.$maxios('get', `/itemTypes/${this.$route.params.id}`).then(response => {
      this.itemType = response.data
      console.log(this.itemType)
    })
  }
}
</script>

<style scoped>
</style>
