import ItemTypeOverview from "@/components/routes/productManagement/itemType/ItemTypeOverview";
import ItemTypeEdit from "@/components/routes/productManagement/itemType/ItemTypeEdit";
import ProductManagement from "@/components/routes/ProductManagement";
import CategoryEdit from "@/components/routes/productManagement/categories/CategoryEdit";
import AttributeEdit from "@/components/routes/productManagement/attribute/AttributeEdit";
import ProcessTemplateEdit from "@/components/routes/productManagement/templates/ProcessTemplateEdit";
import ItemTypes from "@/components/routes/productManagement/itemType/ItemTypes";
import Categories from "@/components/routes/productManagement/categories/Categories";
import Templates from "@/components/routes/productManagement/templates/Templates";
import LocationEdit from "@/components/routes/productManagement/locations/LocationEdit";

const routes = [
    {
        path: '/product-management',
        name: 'product-management',
        component: ProductManagement,
        meta: {
            requiredRights: 'erp_item-types_attributes_get,erp_tags_get,erp_stock_locations_get'
        },
        children: [
            {
                path: 'item-types',
                name: 'product-management-item-types',
                component: ItemTypes,
                meta: {
                    requiredRights: 'erp_item-types_get'
                },
            },
            {
                path: 'categories',
                name: 'product-management-categories',
                component: Categories,
                meta: {
                    requiredRights: 'erp_item-types_categories_get'
                },
            },
            {
                path: 'templates',
                name: 'product-management-templates',
                component: Templates,
                meta: {
                    requiredRights: 'erp_processes_templates_get'
                },
            }
        ]
    },
    {
        path: '/item-types/:id',
        name: 'overview-item-type',
        component: ItemTypeOverview,
        meta: {
            requiredRights: 'erp_item-types_get,erp_stock_get'
        },
    },
    {
        path: '/item-types/:id/edit',
        name: 'edit-item-type',
        component: ItemTypeEdit,
        meta: {
            requiredRights: 'erp_item-types_get,erp_item-types_update'
        },
    },
    {
        path: '/categories/:id/edit',
        name: 'edit-categories',
        component: CategoryEdit,
        meta: {
            requiredRights: 'erp_item-types_categories_update,erp_item-types_categories_get'
        },
    },
    {
        path: '/attributes/:id/edit',
        name: 'edit-attributes',
        component: AttributeEdit,
        meta: {
            requiredRights: 'erp_item-types_attributes_get,erp_item-types_attributes_update'
        },
    },
    {
        path: '/stockLocations/:id/edit',
        name: 'edit-stock-locations',
        component: LocationEdit,
        meta: {
            requiredRights: 'erp_stock_locations_update,erp_stock_locations_get'
        },
    },
    {
        path: '/templates/:id/edit',
        name: 'edit-process-templates',
        component: ProcessTemplateEdit,
        meta: {
            requiredRights: 'erp_processes_templates_update,erp_processes_templates_get'
        },
    }
]

export default routes