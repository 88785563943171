<template>
  <b-modal :id="id"
           v-model="showModal"
           title="Task Overview" size="lg" body-class="no-padding" ok-only>
    <div v-if="process !== null && status !== null">
      <div class="pl-3">
        <b-row>
          <b-col cols="8" class="py-2">
            <b-alert show variant="warning" v-if="process.assignedUsers.length === 0">
              This task has not been assigned.
            </b-alert>

            <template v-if="!process.started && !process.completed">
              <div class="process-block-title">Actions</div>

              <div class="d-flex">
                <b-button @click="markStarted()" variant="primary"
                          class="my-auto" style="padding: 0 .5rem"
                          v-requires-right:[_rightsKey]="'erp_processes_start'">
                  Start Task
                </b-button>
              </div>
            </template>
            <template v-else>
              <div class="process-block-title">Completion</div>
              <div v-if="process.completed"> This task has been completed.</div>
              <template v-else>
                <div
                    v-if="status.childProcesses.length > 0 && status.totalChildProcesses !== status.childProcessesComplete">
                  This process cannot be completed before all subtasks have finished.
                </div>
                <template v-else>
                  <small class="muted">Please fill in how many items below were actually used including items that broke
                    during assembly.</small>
                  <div class="pl-2 pt-2">
                    <div class="py-1" v-for="itemType of itemsUsed" :key="itemType.id">
                      <div>
                        <div class="d-flex">
                          <b-input type="number" @change="ev => itemType.amount = parseInt(ev)" min="1" step="1"
                                   style="max-width: 75px" size="sm" placeholder="Quantity" :value="itemType.amount">
                          </b-input>
                          <span class="my-auto ml-2" style="width: 300px">x {{ itemType.itemTypeName }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br>
                  <div class="d-flex justify-content-center">
                    <b-button @click="markComplete()" variant="primary"
                              class="my-auto" style="padding: 0 .5rem">
                      Mark Complete
                    </b-button>
                  </div>
                </template>
              </template>
            </template>
            <hr>

            <div class="process-block-title">Description</div>
            <p>{{ process.typeToProduce.description }}</p>
            <hr>

            <div class="process-block-title">Attributes</div>
            <b-row>
              <b-col cols="4" v-for="attribute of this.attributeValues" :key="attribute.attribute.id">
                <div>{{ attribute.attribute.name }}</div>
                <template v-if="attribute.value !== null">
                  <template v-if="attribute.attribute.contentType === 'link'">
                    <a :href="attribute.value">{{ attribute.value }}</a>
                  </template>
                  <template
                      v-if="attribute.attribute.contentType === 'number' || attribute.attribute.contentType === 'text'">
                    <div>{{ attribute.value }}</div>
                  </template>
                  <template v-if="attribute.attribute.contentType === 'image' && attribute.attribute.value !== null">
                    <LazyLoadedImage :id="attribute.value.id"></LazyLoadedImage>
                  </template>
                  <template v-if="attribute.attribute.contentType === 'document' && attribute.attribute.value !== null">
                    <LazyLoadedFile :id="attribute.value.id"></LazyLoadedFile>
                  </template>
                </template>
                <template v-else>-</template>
              </b-col>
            </b-row>
          </b-col>

          <!-- Side bar right -->
          <b-col cols="4">
            <div class="py-2 sidebar">
              <div class="block">
                <div class="block-title d-flex justify-content-between">
                  <div>Assignees</div>
                  <UserSearchMultiSelect
                      v-require-all-rights:[_rightsKey]="'erp_processes_assigned-users_add,erp_processes_assigned-users_remove'"
                      :process="process"
                      @removeAssignedUser="removeAssignedUser"
                      @addAssignedUser="addAssignedUser"
                  ></UserSearchMultiSelect>
                </div>
                <div class="block-value" v-if="process.assignedUsers.length > 0">
                  <div v-for="user of process.assignedUsers" :key="user.id">
                    <div class="user-name">{{ user.fullName }}</div>
                    <div class="text-muted user-username">@{{ user.username }}</div>
                  </div>
                </div>
                <div class="block-value" v-else>None</div>
              </div>
              <div class="block">
                <div class="block-title">Location</div>
                <div class="block-value">{{ process.location.name }}</div>
              </div>
              <div class="block">
                <div class="block-title">Labels</div>
                <div class="block-value">
                  {{ process.typeToProduce.tags.join(', ') }}
                </div>
              </div>
              <div class="block">
                <div class="block-title">Categories</div>
                <div class="block-value">
                  {{ process.typeToProduce.categories.join(', ') }}
                </div>
              </div>
              <div class="block">
                <div class="block-title">Manuals</div>
                <div v-if="manualAttributes.length === 0">No manuals found.</div>
                <div v-else>
                  <a :href="manual.href" v-for="manual of manualAttributes" :key="manual.name">
                    {{ manual.name }}
                  </a>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>
import UserSearchMultiSelect from "@/components/routes/processes/UserSearchMultiSelect";
import LazyLoadedImage from "@/components/util/LazyLoadedImage";
import LazyLoadedFile from "@/components/util/LazyLoadedFile";
import {rightUpdateFunctions} from "@/mixins/util";

export default {
  name: "TaskModal",
  components: {LazyLoadedFile, LazyLoadedImage, UserSearchMultiSelect},
  mixins: [rightUpdateFunctions],
  data() {
    return {
      id: 'showTaskModal',
      showModal: false,
      process: null,
      callback: null,
      itemsUsed: [],
      status: null,
      attributeValues: null
    }
  },
  methods: {
    showProcess(process, callback) {
      this.process = process
      this.callback = callback
      this.itemsUsed = []

      const itemCountsTemporary = {}
      for (const item of this.process.reservedItems) {
        if (itemCountsTemporary[item.itemTypeId] === undefined) {
          itemCountsTemporary[item.itemTypeId] = JSON.parse(JSON.stringify(item.itemType))
          itemCountsTemporary[item.itemTypeId].itemTypeId = item.itemTypeId
          itemCountsTemporary[item.itemTypeId].amount = 1
        } else {
          itemCountsTemporary[item.itemTypeId].amount++
        }
      }

      for (const item in itemCountsTemporary) {
        if (Object.prototype.hasOwnProperty.call(itemCountsTemporary, item)) {
          this.itemsUsed.push(itemCountsTemporary[item])
        }
      }

      this.$maxios('get', `/assemblyProcesses/${this.process.id}/progress`).then(progressResponse => {
        this.status = progressResponse.data
        this.$maxios('get', `itemTypes/${this.process.typeToProduceId}`).then(itemTypeResponse => {
          this.attributeValues = itemTypeResponse.data.attributeValues
          for (const item of itemTypeResponse.data.requiredChildTypes) {
            item.amount = item.count
          }
          this.itemsUsed = itemTypeResponse.data.requiredChildTypes
          this.showModal = true
        })
      })
    },
    addAssignedUser(user) {
      this.$maxios('post', `/assemblyProcesses/${this.process.id}/assignedUsers/${user.id}`)
      this.refreshAssignedUsers()
    },
    removeAssignedUser(user) {
      this.$maxios('delete', `/assemblyProcesses/${this.process.id}/assignedUsers/${user.id}`)
      this.refreshAssignedUsers()
    },
    refreshAssignedUsers() {
      setTimeout(() => {
        this.$maxios('get', `/assemblyProcesses/${this.process.id}/assignedUsers`).then(response => {
          this.process.assignedUsers = response.data
          this.$forceUpdate()
          this.callback()
        })
      }, 200)
    },
    markComplete() {
      this.$maxios('post', `assemblyProcesses/${this.process.id}/complete`, {
        itemsUsed: this.itemsUsed,
        childProcessCompletions: []
      }).then(() => {
        this.showModal = false
        this.callback()
      })
    },
    markStarted() {
      this.$maxios('post', `assemblyProcesses/${this.process.id}/start`).then(() => {
        this.process.started = true
        this.callback()
      }).catch(() => {
        this.$alerts.setAlertMessage('Something went wrong', 2, 'danger')
      })
    }
  },
  computed: {
    manualAttributes: function () {
      const regex = /([mM][aA][nN][uU][aA][lL])/g;
      const manuals = []
      if (this.attributeValues === null) return manuals

      for (const attribute of this.attributeValues) {
        if (attribute.attribute.name.match(regex) !== null && attribute.value !== null) {
          manuals.push({
            name: attribute.attribute.name,
            href: attribute.value
          })
        }
      }
      return manuals
    }
  },
}
</script>

<style scoped>
.sidebar {
  border-left: 1px solid lightgray;
  background: #fafafa;
  height: 100%;
  padding: 0 20px;
}

.block {
  padding: 16px 0;
  border-bottom: 1px solid #e8e8e8;
}

.block:last-of-type {
  border-bottom: none;
}

.block-title {
  margin-bottom: 4px;
  color: #303030;
}

.process-block-title {
  margin-bottom: 4px;
  font-weight: 600;
}

.block-value {
  color: #666;
  font-size: .95em;
}

.user-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #32325d;
}

.user-username {
  font-size: 14px;
  line-height: 16px;
}
</style>