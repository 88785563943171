<template>
  <b-modal :id="id"
           @show="resetModal"
           @hidden="resetModal"
           v-model="showModal"
           title="Add new location">
    <form ref="form" @submit="handleOk">
      <b-form-group label="Name" label-for="name-input" :state="nameState"
                    invalid-feedback="Name is required." @change="checkFormValidity">
        <b-form-input ref="name" id="name-input" required
                      tabindex="1"
                      v-model="name" @change="checkFieldValidity('name')"></b-form-input>
      </b-form-group>

      <b-form-group label="Description" label-for="description-input" :state="descriptionState"
                    invalid-feedback="Description is required.">
        <b-form-textarea ref="description" id="description-input" required
                      tabindex="2"
                      v-model="description" @change="checkFieldValidity('description')"></b-form-textarea>
      </b-form-group>

      <b-form-group label="Item type" :state="itemTypeState" invalid-feedback="Item Type cannot be empty.">
        <SingleSearchSelect
            placeholder="Select an item type"
            :provider="searchItemType"
            @itemChanged="itemChanged"
        ></SingleSearchSelect>
      </b-form-group>
      <input type="submit" hidden>
      <b-form-invalid-feedback :state="!formError" v-if="formError">
        Something went wrong.
      </b-form-invalid-feedback>
    </form>
    <template #modal-footer>
      <div class="w-100">
        <b-button v-if="!saving"
                  variant="primary"
                  class="float-right"
                  @click="handleOk"
        >
          Save
        </b-button>
        <b-button v-else variant="primary" class="float-right" disabled>
          <b-spinner small></b-spinner>
          <span class="sr-only">Saving...</span>
        </b-button>
        <b-button
            variant="secondary"
            class="float-right mr-2"
            @click="showModal=false"
        >
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import SingleSearchSelect from "@/components/util/SingleSearchSelect";

export default {
  name: "AddProcessTemplateModal",
  components: {SingleSearchSelect},
  data: function () {
    return {
      id: "AddProcessTemplateModal",
      name: '',
      nameState: null,
      description: '',
      descriptionState: null,
      itemType: null,
      itemTypeState: null,
      showModal: false,
      formError: false,
      saving: false,
      callback: null
    }
  },
  methods: {
    checkFieldValidity(fieldRef) {
      this[fieldRef + 'State'] = this.$refs[fieldRef].checkValidity()
    },
    searchItemType(query, callback) {
      this.$maxios('get', `itemTypes?producible=true&q=${query}`).then(response => {
        const items = response.data.content.map(i => {
          return {value: i, text: i.name}
        })
        callback(items)
      }).catch(() => {
        this.$alerts.setAlertMessage('Something went wrong searching for item types.', 10, 'danger')
        callback([])
      })
    },
    itemChanged(item) {
      this.itemType = item
      this.itemTypeState = this.itemType !== null
    },
    show(callback) {
      this.resetModal()
      this.showModal = true
      this.callback = callback
    },
    checkFormValidity() {
      this.nameState = this.name !== ''
      this.descriptionState = this.description !== ''
      this.itemTypeState = this.itemType !== null
      return this.itemTypeState && this.$refs.form.checkValidity() &&
          this.nameState && this.descriptionState
    },
    resetModal() {
      this.name = ''
      this.description = ''
      this.itemType = null
      this.itemTypeState = null
      this.descriptionState = null
      this.nameState = null
      this.formError = false
      this.saving = false
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      this.saving = true
      const body = {
        typeToProduce: {id: this.itemType.id},
        name: this.name,
        description: this.description
      }
      this.$maxios('post', `/assemblyProcesses/templates`, body)
          .then(() => {
            this.saving = false
            this.showModal = false
            this.callback()
          })
          .catch(() => {
            this.formError = true
            this.saving = false
          })
    }
  }
}
</script>

<style scoped>

</style>
