<template>
  <div class="header">
    <div class="header-content">
      <DropdownMenu v-if="!loadingServices">
        <b-icon slot="trigger" icon="front" class="mx-2 clickable icon-color"></b-icon>
        <div slot="content">
          <a class="service-container" :href="service.href"
             v-for="service of accessibleServices" :key="service.name">
            <img :src="service.iconLink" width="22" height="22" alt="">
            <span class="pl-2">{{ service.name }}</span>
          </a>
        </div>
      </DropdownMenu>
      <DropdownMenu small ref="userMenu">
        <div class="clickable mx-2" slot="trigger">
          <b-avatar variant="light" class="mr-1" size="2em"></b-avatar>
          <span class="pl-1 user-name text-capitalize">{{ username }}</span>
        </div>
        <template #content>
          <span class="dropdown-title text-capitalize">
            Welcome, {{ username }}
          </span>
          <div class="dropdown-divider"></div>
          <router-link :to="{ name: 'profile' }" @click="$refs.userMenu.forceClose()" class="text-decoration-none">
            <div class="dropdown-item-custom">
              <b-icon icon="person-fill" scale="1.1"></b-icon>
              <span class="ml-3">Profile</span>
            </div>
          </router-link>
          <!--          <div class="dropdown-item-custom">-->
          <!--            <b-icon icon="gear-fill"></b-icon>-->
          <!--            <span class="ml-3">Settings</span>-->
          <!--          </div>-->
          <div class="dropdown-divider"></div>
          <div class="dropdown-item-custom" @click="signOut()">
            <b-icon icon="power" scale="1.1"></b-icon>
            <span class="ml-3">Logout</span>
          </div>
        </template>
      </DropdownMenu>
    </div>
  </div>
</template>

<script>
import DropdownMenu from '@/components/util/DropdownMenu'
import {M_AUTH_SIGN_OUT} from "@gyrobian/mux-auth-core";

export default {
  name: "Header",
  components: {
    DropdownMenu
  },
  data() {
    return {
      username: this.$store.state.auth.user.username,
      services: [],
      loadingServices: true
    }
  },
  mounted() {
    this.services = JSON.parse(process.env.VUE_APP_SERVICES)
    const isAdmin = this.$store.getters.getUserInfo.admin
    this.$maxios('get', `${process.env.VUE_APP_AUTH_API_URL}/users/me/services`).then((response) => {
      for (const accessibleService of response.data) {
        for (const registeredService of this.services) {
          registeredService.hasAccess = (registeredService.id === accessibleService.id || isAdmin);
        }
      }
      this.loadingServices = false
    })
  },
  methods: {
    signOut() {
      this.$store.commit(M_AUTH_SIGN_OUT)
    }
  },
  computed: {
    accessibleServices: function () {
      return this.services.filter(service => service.hasAccess)
    }
  }
}
</script>

<style scoped>
.header-content {
  display: flex;
  justify-content: flex-end;
  height: 45px;
  width: 100%;
  padding: 0 2em;
  margin: auto 0;
  align-items: center;
  color: white;
  font-size: 1.1rem;
}

.user-name {
  font-size: .875rem !important;
  font-weight: 600;
  line-height: 20px;
}

.user-name:hover, .icon-color:hover {
  color: rgba(255, 255, 255, 0.7);
}

.header {
  width: 100%;
  height: 100%;
  display: flex;
}

.dropdown-title {
  padding: .5rem 1rem;
  text-transform: uppercase;
  font-size: .625rem;
  font-weight: 600
}

.dropdown-item-custom {
  font-size: .875rem;
  padding: .5rem 1rem;
  color: #32325d;
  user-select: none;
}

.dropdown-item-custom:hover {
  background-color: #f6f9fc;
  cursor: pointer;
}

.dropdown-item-custom:focus {
  outline: none;
}

.service-container {
  display: flex;
  text-decoration: unset;
  color: rgb(50, 50, 93);
  cursor: pointer;
  padding: 5px 10px;
}
</style>