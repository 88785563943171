<template>
  <div class="snackbar-container">
    <b-alert
        :variant="variant"
        dismissible
        fade
        :show="show"
        @dismissed="show=false"
    >
      {{ message }}
    </b-alert>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  data: function () {
    return {
      message: '',
      show: false,
      variant: 'info'
    }
  },
  methods: {
    /**
     * @param message - Message to display
     * @param timeoutSeconds - Timeout after which this alert will disappear, in seconds.
     * @param variant - The color variant of this alert. E.g. info, danger, etc.
     */
    setAlertMessage (message, timeoutSeconds = 2, variant = 'info') {
      this.variant = variant
      if (!message || message === '') {
        return
      }
      this.message = message
      this.show = timeoutSeconds
    }
  }
}
</script>

<style scoped>

.snackbar-container {
  position: absolute;
  z-index: 1000;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
}
</style>
