<template>
  <div>
    <div class="sub-page-header">
      <div class="container-fluid py-4 d-flex justify-content-between">
        <h3 class="page-title">Administration</h3>
      </div>
    </div>
    <div class="container-fluid" style="margin-top: -4.5rem;">
      <b-card title="Card Title" no-body>
        <b-card-header header-tag="nav">
          <b-nav card-header tabs>
            <b-nav-item ref="users" v-requires-right:[_rightsKey]="'users_get'" :to="{ name: 'administration-users' }" exact exact-active-class="active">Users</b-nav-item>
            <b-nav-item ref="services" v-requires-right:[_rightsKey]="'services_get'" :to="{ name: 'administration-services' }" exact exact-active-class="active">Services</b-nav-item>
            <b-nav-item ref="roles" v-requires-right:[_rightsKey]="'roles_get'" :to="{ name: 'administration-roles' }" exact exact-active-class="active">Roles</b-nav-item>
            <b-nav-item ref="rights" v-requires-right:[_rightsKey]="'rights_get'" :to="{ name: 'administration-rights' }" exact exact-active-class="active">Rights</b-nav-item>
          </b-nav>
        </b-card-header>

        <b-card-body>
          <router-view class="no-border white-header"></router-view>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import {rightUpdateFunctions} from "@/mixins/util";

export default {
  name: "administration",
  mixins: [rightUpdateFunctions],
  mounted () {
    // We only wanna do this redirect when we are not in a sub route.
    if (this.$route.name !== 'administration') return

    if (!this.$refs.users.hidden) {
      this.$router.replace({ name: 'administration-users' })
      return
    }
    if (!this.$refs.services.hidden) {
      this.$router.replace({ name: 'administration-services' })
      return
    }
    if (!this.$refs.roles.hidden) {
      this.$router.replace({ name: 'administration-rights' })
      return
    }
    if (!this.$refs.rights.hidden) {
      this.$router.replace({ name: 'administration-roles' })
    }
  }
}
</script>

<style scoped>

</style>