<template>
  <div>
    <OverlayWithText :show="saving"></OverlayWithText>
    <div class="sub-page-header">
      <div class="container-fluid py-4 d-flex justify-content-between">
        <h3 class="page-title sm">Edit Attribute</h3>
        <div>
          <b-button :disabled="!needSaving" variant="light" size="sm"
                    class="mr-3 hop-btn" @click="reset()">Reset
          </b-button>
          <b-button :disabled="!needSaving" variant="light" size="sm"
                    class="hop-btn" @click="save()">Save
          </b-button>
        </div>
      </div>
    </div>
    <div class="container-fluid" style="margin-top: -4.5rem;">
      <b-card class="edit-container" v-if="!loading">
        <b-form @submit.prevent="">
          <b-form-group label="Name" label-for="name-input">
            <b-form-input
                id="name-input"
                v-model="attribute.name"
                :state="validName"
                required></b-form-input>
            <b-form-invalid-feedback :state="validName">
              Name cannot be empty.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Content Type" label-for="content-type-input"
                        description="Changing the content type might remove all existing associated values.">
            <b-form-select
                id="content-type-input"
                v-model="attribute.contentType"
                :state="validContentType"
                :options="contentTypes"
                required></b-form-select>
            <b-form-invalid-feedback :state="validContentType">
              Content type cannot be empty.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import OverlayWithText from "@/components/util/OverlayWithText";


export default {
  name: "AttributeEdit",
  components: {OverlayWithText},
  data() {
    return {
      saving: false,
      loading: true,
      attribute: {},
      attributeCopy: '',
      contentTypes: [
        {text: "Text", value: "text"},
        {text: "Number", value: "number"},
        {text: "Image", value: "image"},
        {text: "Link", value: "link"},
        {text: "Document", value: "document"},
      ]
    }
  },
  mounted() {
    this.$maxios('get', 'itemTypeAttributes/' + this.$route.params.id).then((response) => {
      this.attribute = response.data
      this.attributeCopy = JSON.stringify(response.data)
      this.loading = false
    }).catch(() => {
      this.$alerts.setAlertMessage('The attribute could not be retrieved.', 2, 'danger')
    })
  },
  methods: {
    reset() {
      this.attribute = JSON.parse(this.attributeCopy)
    },
    save() {
      this.saving = true
      const id = this.$route.params.id
      this.$maxios('patch', `itemTypeAttributes/${id}`, this.attribute).then(() => {
        // this.$router.replace({name: 'overview-attribute', params: {id: id}})
        this.$router.replace({name: 'product-management'})
      }).catch(() => {
        this.saving = false
        this.$alerts.setAlertMessage('An error occurred try again later.', 2, 'danger')
      })
    }
  },
  computed: {
    needSaving: function () {
      return JSON.stringify(this.attribute) !== this.attributeCopy
    },
    validName: function () {
      return this.attribute.name !== null && this.attribute.name !== ""
    },
    validContentType: function () {
      return this.attribute.contentType !== null && this.attribute.contentType !== ""
    }
  }
}
</script>

<style scoped>
.edit-container {
  padding: 0.75rem 1.25rem;
  color: #222222;
}
</style>