<template>
  <div>
    <div class="sub-page-header">
      <div class="container-fluid py-4 d-flex justify-content-between">
        <h3 class="page-title sm">New process</h3>
        <div class="d-flex">
          <b-button variant="light" size="sm" @click="loadTemplateSelectModal" class="hop-btn mr-2">Use Template
          </b-button>
        </div>
      </div>
    </div>
    <div class="container-fluid " style="margin-top: -4.5rem;">
      <b-row>
        <b-col cols="8">
          <b-card class="p-2 px-3">
            <b-form-group label="Name" description="Optional name.">
              <b-input type="text" v-model="name"></b-input>
            </b-form-group>
            <b-form-group label="Description" description="Optional description.">
              <b-textarea v-model="description"></b-textarea>
            </b-form-group>
            <b-form-group label="Item type">
              <SingleSearchSelect
                  ref="itemTypeSelect"
                  placeholder="Select an item type"
                  :provider="searchItemType"
                  @itemChanged="itemChanged"></SingleSearchSelect>
            </b-form-group>
            <b-form-group label="Location">
              <SingleSearchSelect
                  placeholder="Select a location"
                  :provider="searchLocation"
                  @itemChanged="locationChanged"></SingleSearchSelect>
            </b-form-group>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card style="min-height: 100px">
            <template v-if="requirements" #header>
              <span>{{ requirements.typeToProduce.name }} at {{ location.name }}</span>
            </template>
            <div class="p-2 px-3">
              <span v-if="requirements === null">Select a process type and location.</span>
              <template v-else>
                <div><strong>Production possible: </strong>
                  <b-icon v-if="requirements.productionPossible" icon="check" scale="1.8" variant="success"></b-icon>
                  <b-icon v-else icon="x" scale="1.8" variant="danger"></b-icon>
                </div>

                <div><strong>Problems: </strong></div>
                <ul class="mb-0"><li v-for="(error, index) of conciseErrors" :key="index">{{ error }}</li></ul>

                <div><strong>Categories:</strong></div>
                <div>{{ requirements.typeToProduce.categories.join(', ') }}</div>

                <div><strong>Tags:</strong></div>
                <div>{{ requirements.typeToProduce.tags.join(', ') }}</div>

                <div><strong>Requirements:</strong></div>
                <ul>
                  <li v-for="(item, index) of formattedRequiredChildTypes" :key="index">
                    {{ item }}
                  </li>
                </ul>
                <b-button @click="makeProcesses()" :disabled="!requirements.productionPossible" variant="success"
                          style="width: 100%">
                  Create processes
                </b-button>
              </template>
            </div>
            <b-overlay :show="showOverlay" no-wrap>
            </b-overlay>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <SelectTemplateModal ref="selectTemplateModal"></SelectTemplateModal>
  </div>
</template>

<script>
import SingleSearchSelect from "@/components/util/SingleSearchSelect";
import SelectTemplateModal from "@/components/routes/processes/SelectTemplateModal";

export default {
  name: "NewProcess",
  components: {SelectTemplateModal, SingleSearchSelect},
  data() {
    return {
      showOverlay: false,
      name: null,
      description: null,
      itemType: null,
      location: null,
      requirements: null,
      errors: [],
      conciseErrors: []
    }
  },
  methods: {
    searchItemType(query, callback) {
      this.$maxios('get', `itemTypes?producible=true&q=${query}`).then(response => {
        const items = response.data.content.map(i => {
          return {value: i, text: i.name}
        })
        callback(items)
      }).catch(() => {
        this.$alerts.setAlertMessage('Something went wrong searching for item types.', 10, 'danger')
        callback([])
      })
    },
    searchLocation(query, callback) {
      this.$maxios('get', `stockLocations?q=${query}`).then(response => {
        const items = response.data.content.map(i => {
          return {value: i, text: i.name}
        })
        callback(items)
      }).catch(() => {
        this.$alerts.setAlertMessage('Something went wrong searching for item types.', 10, 'danger')
        callback([])
      })
    },
    loadTemplateSelectModal() {
      this.$refs.selectTemplateModal.show(template => {
        this.itemType = template.typeToProduce
        this.name = template.name
        this.description = template.description
        this.$refs.itemTypeSelect.setItemTextFromOutside(this.itemType.name)
        this.getRequirements()
      })
    },
    itemChanged(item) {
      this.itemType = item
      this.getRequirements()
    },
    locationChanged(location) {
      this.location = location
      this.getRequirements()
    },
    getRequirements() {
      if (this.itemType !== null && this.location !== null) {
        this.showOverlay = true
        this.$maxios('post', `assemblyProcesses/requirements`, {
          typeToProduceId: this.itemType.id,
          locationId: this.location.id
        }).then(response => {
          this.requirements = response.data
          this.showOverlay = false
          this.errors = []
          this.conciseErrors = []
          this.parseRequirementsForErrors(this.requirements, null)
        })
      }
    },
    /**
     * The requirements response is the first 'requirementType'
     *
     * @param requirementType
     * @param parent
     */
    parseRequirementsForErrors(requirementType, parent) {
      if (requirementType.requiredChildTypes.length === 0 && requirementType.productionPossible === false) {
        this.errors.push(
            `Missing ${requirementType.amountRequired - requirementType.amountAvailable} ` +
            `${requirementType.typeToProduce.name}(s) in ${this.location.name}, ` +
            `needed to produce ${parent.amountRequired - parent.amountAvailable} ` +
            `${parent.typeToProduce.name}(s).`
        )
        this.conciseErrors.push(
            `Missing ${requirementType.amountRequired - requirementType.amountAvailable} ` +
            `${requirementType.typeToProduce.name}(s)`
        )
      }
      for (let i = 0; i < requirementType.requiredChildTypes.length; i++) {
        this.parseRequirementsForErrors(requirementType.requiredChildTypes[i], requirementType)
      }
    },
    makeProcesses() {
      this.showOverlay = true
      const body = {
        typeToProduceId: this.itemType.id,
        locationId: this.location.id
      }
      if (this.description !== null && this.description !== '') {
        body.description = this.description
      }
      if (this.name !== null && this.name !== '') {
        body.name = this.name
      }
      console.log(body)
      this.$maxios('post', `assemblyProcesses`, body).then(response => {
        this.$router.push({name: 'process', params: {id: response.data.id}})
        this.showOverlay = false
      }).catch(() => {
        this.$alerts.setAlertMessage('The process could not be made.', 2, 'danger')
      })
    }
  },
  computed: {
    formattedRequiredChildTypes: function () {
      if (this.requirements !== null) {
        return this.requirements.typeToProduce.requiredChildTypes
            .map(i => `${i.count}x ${i.itemTypeName}`)
      }
      return []
    }
  }
}
</script>

<style scoped>

</style>