import Vue from 'vue'
import VueRouter from 'vue-router'
import Inventory from "@/components/routes/Warehouse";
import Error from "@/components/error/Error";
import Inventorizor from "@/components/Inventorizor";
import Processes from "@/components/routes/Processes";
import Dashboard from "@/components/routes/Dashboard";
import NewProcess from "@/components/routes/processes/NewProcess";
import AddItemsWarehouse from "@/components/routes/warehouse/AddItems";
import RemoveItemsWarhouse from "@/components/routes/warehouse/RemoveItems";
import {verifyAccessParameters} from "@gyrobian/mux-auth-core";
import Process from "@/components/routes/processes/Process";

// Route Collections
import productManagementRoutes from '@/router/routeCollections/productManagementRoutes'
import administrationRoutes from '@/router/routeCollections/administrationRoutes'
import store from "@/store";
import ItemTypeSpecificWarehouse from "@/components/routes/warehouse/ItemTypeSpecificWarehouse";

Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        name: 'notFound',
        component: Error
    },
    {
        path: '/forbidden',
        name: 'forbidden',
        component: Error,
        props: {
            errorCode: 403
        }
    },
    {
        path: '/',
        name: 'basis',
        redirect: 'dashboard',
        component: Inventorizor,
        children: [
            {
                path: '/dashboard',
                name: 'home',
                component: Dashboard,
                meta: {
                    requiredRights: 'erp_processes_assigned-users_get'
                }
            },
            {
                path: '/warehouse',
                name: 'warehouse',
                component: Inventory,
                meta: {
                    requiredRights: 'erp_stock_get,erp_stock_locations_get,erp_stock_get'
                }
            },
            {
                path: '/warehouse/single/:id',
                name: 'warehouse-single-item',
                component: ItemTypeSpecificWarehouse,
                meta: {
                    requiredRights: 'erp_stock_get,erp_stock_locations_get,erp_stock_get'
                }
            },
            {
                path: '/warehouse/register',
                name: 'warehouse-register',
                component: AddItemsWarehouse,
                meta: {
                    requiredRights: 'erp_stock_locations_get,erp_item-types_get,erp_stock_create,erp_stock_bulk-create,erp_stock_get'
                }
            },
            {
                path: '/warehouse/bulk-removal',
                name: 'warehouse-bulk-removal',
                component: RemoveItemsWarhouse,
                meta: {
                    requiredRights: 'erp_stock_locations_get,erp_item-types_get,erp_stock_bulk-remove,erp_stock_remove'
                }
            },
            {
                path: '/processes',
                name: 'processes',
                component: Processes,
                meta: {
                    requiredRights: 'erp_processes_get'
                }
            },
            {
                path: '/processes/new',
                name: 'new-process',
                component: NewProcess,
                meta: {
                    requiredRights: 'erp_processes_create,erp_stock_locations_get,erp_item-types_get,erp_processes_templates_get'
                }
            },
            {
                path: '/processes/:id',
                name: 'process',
                component: Process,
                meta: {
                    requiredRights: 'erp_processes_get'
                }
            },
            {
                path: '/settings',
                name: 'settings',
                component: Dashboard,
                meta: {
                    requiredRights: 'erp_processes_get'
                }
            }
        ]
            .concat(productManagementRoutes)
            .concat(administrationRoutes)
    }
]

function addAuthOnRoute(route) {
    route.beforeEnter = (to, from, next) => verifyAccessParameters(to, from, next, store)
    if (route.children !== undefined) {
        for (const child of route.children) {
            addAuthOnRoute(child)
        }
    }
}

for (const route of routes) {
    addAuthOnRoute(route)
}

const router = new VueRouter({
    mode: 'history',
    routes
})

document.title = 'Muxsan ERP'

export default router