<template>
  <b-modal :id="id"
           @show="resetModal"
           @hidden="resetModal"
           v-model="showModal"
           title="Add new attribute">
    <form ref="form" @submit.prevent="handleOk">
      <b-form-group
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="nameState"
      >
        <b-form-input
            id="name-input"
            v-model="name"
            :state="nameState"
            required
            ref="nameInput"
            v-on:change="checkFieldValidity('nameState', 'nameInput')"
        ></b-form-input>
      </b-form-group>
      <b-form-invalid-feedback :state="!formError" v-if="formError">
        Something went wrong.
      </b-form-invalid-feedback>

      <b-form-group label="Content Type" label-for="content-type-input">
        <b-form-select
            id="content-type-input"
            v-model="contentType"
            :state="contentTypeState"
            :options="contentTypes"
            ref="contentTypeInput"
            v-on:change="contentTypeEdited = true"
            required
        ></b-form-select>
        <b-form-invalid-feedback :state="contentTypeState">
          Content type cannot be empty.
        </b-form-invalid-feedback>
      </b-form-group>
    </form>
    <template #modal-footer>
      <div class="w-100">
        <b-button v-if="!saving"
                  variant="primary"
                  class="float-right"
                  @click="handleOk"
        >
          Save
        </b-button>
        <b-button v-else variant="primary" class="float-right" disabled>
          <b-spinner small></b-spinner>
          <span class="sr-only">Saving...</span>
        </b-button>
        <b-button
            variant="secondary"
            class="float-right mr-2"
            @click="showModal=false"
        >
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "AddAttributeModal",
  data: function () {
    return {
      id: 'addLocationModal',
      nameState: null,
      name: '',
      contentType: '',
      showModal: false,
      formError: false,
      contentTypeEdited: true,
      saving: false,
      callback: null,
      contentTypes: [
        "Text",
        "Number",
        "Image",
        "Link",
        "Document"
      ]
    }
  },
  methods: {
    show(callback) {
      this.showModal = true
      this.callback = callback
    },
    checkFormValidity() {
      return this.$refs.form.checkValidity()
    },
    checkFieldValidity(fieldName, fieldRef) {
      this[fieldName] = this.$refs[fieldRef].checkValidity()
    },
    resetModal() {
      this.name = ''
      this.contentType = ''
      this.nameState = null
      this.formError = false
      this.saving = false
      this.contentTypeEdited = false
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      this.saving = true
      this.$maxios('post', `${process.env.VUE_APP_API_URL}/itemTypeAttributes`,
          {name: this.name, contentType: this.contentType})
          .then(() => {
            this.saving = false
            this.showModal = false
            this.callback()
          })
          .catch(() => {
            this.formError = true
            this.saving = false
          })
    }
  },
  computed: {
    contentTypeState: function () {
      return !this.contentTypeEdited ? null : this.contentType !== ''
    }
  }
}
</script>

<style scoped>

</style>