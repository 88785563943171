<template>
  <div>
    <div class="sub-page-header">
      <div class="container-fluid py-4 d-flex justify-content-between">
        <h3 class="page-title">Warehouse</h3>
        <div class="d-flex">
          <router-link :to="{ name: 'warehouse-bulk-removal' }">
            <b-button
                v-require-all-rights:[_rightsKey]="'erp_stock_locations_get,erp_item-types_get,erp_stock_bulk-remove,erp_stock_remove'"
                variant="light" size="sm" class="hop-btn mr-2">
              Bulk Removal
            </b-button>
          </router-link>
          <router-link :to="{ name: 'warehouse-register' }">
            <b-button
                v-require-all-rights:[_rightsKey]="'erp_stock_locations_get,erp_item-types_get,erp_stock_create,erp_stock_bulk-create,erp_stock_get'"
                variant="light" size="sm" class="hop-btn">
              Register
            </b-button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="container-fluid" style="margin-top: -4.5rem;">
      <b-row>
        <b-col>
          <Table
              small
              :page-options="[10, 20, 50, 100]"
              api-url="/itemTypes/stock"
              :fields="fields"
              :filter-search-provider="searchLocation"
              per-page-customizable
              searchable
              filterable
              clickable-row
              @row-clicked="rowClicked"
              filter-query-param-name="locationId"
          ></Table>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import '@/assets/styles/layout.css'
import Table from "@/components/routes/Table"
import {rightUpdateFunctions} from "@/mixins/util";

export default {
  name: "Inventory",
  components: {Table},
  mixins: [rightUpdateFunctions],
  data: function () {
    return {
      fields: [
        {
          key: 'itemTypeName',
          label: 'Item Type',
          sortable: false
        },
        {
          key: 'amount',
          sortable: false,
          label: 'Amount'
        },
        {
          key: 'amountReserved',
          sortable: false,
          label: 'Reserved'
        },
        {
          key: 'amountAvailable',
          sortable: false,
          label: 'Available'
        }
      ]
    }
  },
  methods: {
    searchLocation(query, callback) {
      this.$maxios('get', `stockLocations?&q=${query}`).then(response => {
        const items = response.data.content.map(i => {
          return {value: i.id, text: i.name}
        })
        callback(items)
      }).catch(() => {
        this.$alerts.setAlertMessage('Something went wrong searching for locations.', 10, 'danger')
        callback([])
      })
    },
    rowClicked(row) {
      this.$router.push({name: 'warehouse-single-item', params: {id: row.itemTypeId}})
    }
  }
}
</script>

<style scoped>
</style>