<template>
  <div class="">
    <div class="process-container">
      <div class="process">
        <div class="process-header">
          <div class="process-row" style="justify-content: space-between">
            <div class="my-auto"><strong>{{ process.typeToProduce.name }}</strong></div>
            <div class="my-auto clickable" v-if="nodes.length > 0">
              <b-icon @click="toggleChildren()" v-if="showChildren" icon="caret-up-fill"></b-icon>
              <b-icon @click="toggleChildren()" v-else icon="caret-down-fill"></b-icon>
            </div>
          </div>
        </div>
        <div class="process-row">
          <div>
            <div class="pr-3">
              <div class="process-info-sub-header">Assignees</div>
              <div class="d-flex">
                <div v-if="process.assignedUsers.length > 0">
                  <span>{{ process.assignedUsers.map(user => user.username).join(', ') }}</span>
                </div>
                <div v-else>None</div>
              </div>
            </div>
          </div>
          <div>
            <div class="process-info-sub-header">Status</div>
            <div class="pr-2">
              {{ statusFormatter(process.status) }}
              <template v-if="process.completed"><b-icon variant="success" scale="2" icon="check"></b-icon></template>
            </div>
          </div>
        </div>
        <div class="process-footer">
          <div class="process-row d-flex justify-content-between">
            <small class="text-muted">{{ nodes.length }} Subtasks</small>
            <b-button @click="showProcessModal(process)" size="sm" variant="primary"
                      class="my-auto" style="padding: 0 .5rem">
              More Info
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <template v-if="showChildren">
      <b-collapse :id="process.id + '-collapse'">
        <RecursiveSubProcess
            class="ml-3 outside-pad"
            :process="childValues[index]"
            :nodes="childValues[index].childrenIds"
            v-for="(node, index) of nodes"
            :key="childValues[index].id"></RecursiveSubProcess>
      </b-collapse>
    </template>
    <TaskModal ref="taskModal"></TaskModal>
  </div>
</template>

<script>
import TaskModal from "@/components/routes/processes/TaskModal";

export default {
  name: "RecursiveSubProcess",
  components: {TaskModal},
  data: function () {
    return {
      showChildren: false,
      loadingChildren: false,
      childValues: null
    }
  },
  props: {
    process: Object,
    nodes: {
      type: Array
    }
  },
  methods: {
    async toggleChildren() {
      if (this.childValues === null) {
        this.loadingChildren = true
        this.childValues = []
        for (const child of this.nodes) {
          await this.$maxios('get', `assemblyProcesses/${child}`).then(response => {
            this.childValues.push(response.data)
          })
        }
        for (const child of this.childValues) {
          await this.$maxios('get',
              `/assemblyProcesses/${child.id}/assignedUsers`).then(usersResponse => {
            child.assignedUsers = usersResponse.data
          })
        }
      }
      this.loadingChildren = false
      this.showChildren = !this.showChildren
      setTimeout(() => {
        this.$root.$emit('bv::toggle::collapse', this.process.id + '-collapse')
      }, 100)
    },
    showProcessModal(process) {
      this.$refs.taskModal.showProcess(process, () => {
        this.toggleChildren()
        this.showChildren = !this.showChildren
      })
    },
    statusFormatter(element) {
      switch (element) {
        case 'NOT_STARTED':
          return 'Not yet started'
        case 'COMPLETE':
          return 'Finished'
        case 'IN_PROGRESS':
          return 'In progress'
      }
      return '-'
    }
  }
}
</script>

<style scoped>
.process-container {
  /*border-left: 1px solid #e3e3e3;*/
  margin-bottom: -1px;
}

.outside-pad {
  margin-top: .5em;
}

.process {
  width: 100%;
  border: 1px #e3e3e3 solid;
  /*border-left: none;*/
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.process .process-row {
  padding: .25em .5em;
  display: flex;
  justify-content: left;
}

.process:hover, .process-container:hover {
  border-color: lightblue;
}

.process-info-sub-header {
  color: #5e6c84;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: .04em;
  line-height: 16px;
  text-transform: uppercase;
  display: block;
  line-height: 20px;
  margin: 0 8px 4px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.process-header {
  background-color: #f7f7f7;
  border-bottom: 1px #e3e3e3 solid;
}

.process-footer {
  /*background-color: #f7f7f7;*/
  border-top: 1px #e3e3e3 solid;
}

</style>