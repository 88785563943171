import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import router from './router'
import store from './store'
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import {
  verifySingleRight,
  verifyMultipleRightUsingOr,
  verifyMultipleRightUsingAnd,
  verifyMultipleRightOrGroupsUsingInternalAnd
} from "@gyrobian/mux-auth-core";
import { initializeMuxsanAuthCore } from "@gyrobian/mux-auth-core";
import VueQrcodeReader from "vue-qrcode-reader";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueApexCharts)
Vue.use(VueQrcodeReader);

Vue.component('apexchart', VueApexCharts)
Vue.directive('requires-right', verifySingleRight)
Vue.directive('require-one-of-rights', verifyMultipleRightUsingOr)
Vue.directive('require-all-rights', verifyMultipleRightUsingAnd)
Vue.directive('require-one-right-group', verifyMultipleRightOrGroupsUsingInternalAnd)

axios.defaults.baseURL = process.env.VUE_APP_API_URL

if (process.env.VUE_APP_FRONT_END_VARIANT !== "development") {
  Sentry.init({
    Vue,
    environment: process.env.VUE_APP_FRONT_END_VARIANT,
    dsn: "https://9a12acc00c7d4cf5a2e1405399198328@o1133231.ingest.sentry.io/6179678",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "erp.muxsan.io", "erp.muxsan.com", /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

initializeMuxsanAuthCore()

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
