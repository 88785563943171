<template>
  <router-view id="app" ></router-view>
</template>

<script>
import '@/assets/styles/general.css'
import Vue from 'vue';
import {maxiosInternalWrapper} from "@gyrobian/mux-auth-core";

export default {
  name: 'App',
  mounted() {
    /**
     * $maxios is the Muxsan axios wrapper that allows us to verify tokens before actually sending a message.
     *
     * @param method
     * @param axiosArgs
     * @returns {Promise<unknown>}
     */
    Vue.prototype.$maxios = (method, ...axiosArgs) => {
      return maxiosInternalWrapper(this.$store, this.$router, this.$route, method, ...axiosArgs)
    }
  },
  computed: {
    rightsKey: function () {
      return btoa(JSON.stringify(this.$store.getters.getRights))
    }
  }
}
</script>

<style>
html, body {
  color: #32325d !important;
}

.clickable {
  cursor: pointer;
}
</style>
