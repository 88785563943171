<template>
  <img v-if="image" :src="'data:' + image.mimeType + ';base64,' + image.base64EncodedContent"
       alt="image" height="200">
</template>

<script>
export default {
  name: "LazyLoadedImage",
  props: {
    id: {
      type: Number
    },
  },
  data() {
    return {
      image: null
    }
  },
  beforeMount() {
    let config = {headers: {Accept: `application/json`}}
    this.$maxios('get', `/files/${this.id}`, config).then(imageResponse => {
      this.image = imageResponse.data
    })
  }
}
</script>

<style scoped>

</style>