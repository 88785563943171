<template>
  <div class="tag-container">
    <b-badge v-for="(item, index) of items" :key="index" class="mr-1">
      {{ item.text }}
      <span @click="removeItem(index)" class="clickable"><b-icon icon="x" scale="1.5"></b-icon></span>
    </b-badge>
    <input @keyup="showAndSearch()" @focusin="showAndSearch()"
           class="internal-search" placeholder="Select items" v-model="query">
    <b-spinner variant="info" small v-if="loading" class="float-right" style=" margin-top: 5px; margin-right: 5px"></b-spinner>
    <div v-if="showResults" class="search-results-container">
      <div class="search-result-element"
           v-for="(option, index) of filteredOptions" :key="index"
           @click="addItem(option)">
        {{ option.text }}
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: "MultipleSearchSelect",
  props: {
    provider: {
      type: Function || Array
    },
    parentItems: {
      type: Array
    }
  },
  data: function () {
    return {
      loading: false,
      options: [],
      query: '',
      items: [],
      lastSelectItem: {},
      showResults: false
    }
  },
  methods: {
    addItem (item) {
      this.items.push(item)
      this.$emit('itemsChanged', JSON.parse(JSON.stringify(this.items)))
      this.closeResults()
      this.query = ''
      this.search()
    },
    removeItem(item) {
      this.items.splice(item, 1)
      this.$emit('itemsChanged', JSON.parse(JSON.stringify(this.items)))
    },
    showAndSearch () {
      this.showResults = true
      this.search()
    },
    search () {
      if (typeof this.provider === "function") {
        this.loading = true
        this.provider(this.query, i => this.setOptions(i))
      }
    },
    setOptions (options) {
      this.options = options
      this.loading = false
    },
    closeResults () {
      this.showResults = false
    },
    closeEvent (e) {
      if (!this.$el.contains(e.target)) {
        this.closeResults()
      }
    },
    resetItems () {
      setTimeout(() => {
        this.items = JSON.parse(JSON.stringify(this.parentItems))
      }, 100)
    }
  },
  computed: {
    filteredOptions: function () {
      const options = []
      for (const result of this.options) {
        let add = true;
        for (const item of this.items) {
          if (result.text === item.text) {
            add = false
          }
        }
        if (add) {
          options.push(result)
        }
      }
      if (options.length === 0){
        this.closeResults()
      }
      return options
    }
  },
  mounted () {
    this.items = JSON.parse(JSON.stringify(this.parentItems))
    document.addEventListener('click', this.closeEvent)
    this.search()
  },
  beforeDestroy () {
    document.removeEventListener('click', this.closeEvent)
  }
}
</script>

<style scoped>
.tag-container {
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 4px;
  height: 38px;
  padding: 5px;
  position: relative;
}
.tag-container:hover {
  border-color: #dedede;
}
.tag-container:focus-within {
  border-color: lightblue;
}
.internal-search, .internal-search:focus {
  border: none;
  outline: none !important;
}
.search-results-container {
  position: absolute;
  top: 34px;
  left: -1px;
  width: calc(100% + 2px);
  min-height: 5px;
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid lightblue;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 1px solid #dedede;
  background: white;
  z-index: 9999;
}
.search-result-element {
  padding: 5px 10px;
}
.search-result-element:hover {
  background-color: #dedede;
  cursor: pointer;
}
</style>