<template>
  <Table title="Categories" :per-page="6" api-url="/itemTypeCategories"
         :fields="categoriesFields"
         overview-link-key="id"
         edit-link-route-name="edit-categories"
         delete-required-rights="erp_item-types_categories_remove"
         edit-required-rights="erp_item-types_categories_update"
         deletable
  ></Table>
</template>

<script>
import Table from "@/components/routes/Table";
export default {
  name: "Categories",
  components: {Table},
  data() {
    return {
      categoriesFields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'description',
          sortable: false
        },
        'Options'
      ]
    }
  },
}
</script>

<style scoped>

</style>