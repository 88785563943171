<template>
  <b-modal :id="id"
           @show="resetModal"
           @hidden="resetModal"
           v-model="showModal"
           title="Add new tag">
    <form ref="form" @submit.prevent="handleOk">
      <b-form-group
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="nameState"
      >
        <b-form-input
            id="name-input"
            v-model="name"
            :state="nameState"
            required
            ref="nameInput"
            v-on:change="checkFieldValidity('nameInput')"
        ></b-form-input>
      </b-form-group>
      <b-form-invalid-feedback :state="!formError" v-if="formError">
        Something went wrong.
      </b-form-invalid-feedback>
    </form>
    <template #modal-footer>
      <div class="w-100">
        <b-button v-if="!saving"
            variant="primary"
            class="float-right"
            @click="handleOk"
        >
          Save
        </b-button>
        <b-button v-else variant="primary" class="float-right" disabled>
          <b-spinner small></b-spinner>
          <span class="sr-only">Saving...</span>
        </b-button>
        <b-button
            variant="secondary"
            class="float-right mr-2"
            @click="showModal=false"
        >
          Cancel
        </b-button>
      </div>
    </template>
    
  </b-modal>
</template>

<script>


export default {
  name: "AddTagModal",
  data: function () {
    return {
      id: 'addTagModal',
      nameState: null,
      name: '',
      showModal: false,
      formError: false,
      saving: false,
      callback: null
    }
  },
  methods: {
    show (callback) {
      this.showModal = true
      this.callback = callback
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.checkFieldValidity('nameInput')
      return valid
    },
    checkFieldValidity(fieldRef) {
      this.nameState = this.$refs[fieldRef].checkValidity()
    },
    resetModal() {
      this.name = ''
      this.nameState = null
      this.formError = false
      this.saving = false
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      this.saving = true
      this.$maxios('post', `${process.env.VUE_APP_API_URL}/tags`, {name: this.name})
          .then(() => {
            this.saving = false
            this.showModal = false
            this.callback()
          })
          .catch(() => {
            this.formError = true
            this.saving = false
          })
    }
  }
}
</script>

<style scoped>

</style>