<template>
  <b-modal :id="id"
           @show="resetModal"
           @hidden="resetModal"
           v-model="showModal"
           title="Add new category">
    <form ref="form" @submit.prevent="handleOk">
      <b-form-group
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="nameState"
      >
        <b-form-input
            id="name-input"
            v-model="name"
            :state="nameState"
            required
            ref="nameInput"
            v-on:change="checkFieldValidity('nameState', 'nameInput')"
        ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Description"
          label-for="description-input"
          invalid-feedback="Description is required"
          :state="descriptionState"
      >
        <b-form-input
            id="description-input"
            v-model="description"
            :state="descriptionState"
            required
            ref="descriptionInput"
            v-on:change="checkFieldValidity('descriptionState','descriptionInput')"
        ></b-form-input>
      </b-form-group>

      <!-- Generic Form error handler. -->
      <b-form-invalid-feedback :state="!formError" v-if="formError">
        Something went wrong.
      </b-form-invalid-feedback>
    </form>

    <!-- Cancel and Save buttons. -->
    <template #modal-footer>
      <div class="w-100">
        <b-button v-if="!saving"
                  variant="primary"
                  class="float-right"
                  @click="handleOk"
        >
          Save
        </b-button>
        <b-button v-else variant="primary" class="float-right" disabled>
          <b-spinner small></b-spinner>
          <span class="sr-only">Saving...</span>
        </b-button>
        <b-button
            variant="secondary"
            class="float-right mr-2"
            @click="showModal=false"
        >
          Cancel
        </b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>


export default {
  name: "AddCategoryModal",
  data: function () {
    return {
      id: 'addCategoryModal',
      nameState: null,
      name: '',
      descriptionState: null,
      description: '',
      showModal: false,
      formError: false,
      saving: false,
      callback: null
    }
  },
  methods: {
    show (callback) {
      this.showModal = true
      this.callback = callback
    },
    checkFormValidity() {
      this.checkFieldValidity('nameState', 'nameInput')
      this.checkFieldValidity('descriptionState', 'descriptionInput')
      return this.$refs.form.checkValidity()
    },
    checkFieldValidity(state, fieldRef) {
      this[state] = this.$refs[fieldRef].checkValidity()
    },
    resetModal() {
      this.name = ''
      this.nameState = null
      this.descriptionState = null
      this.formError = false
      this.saving = false
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      this.saving = true
      this.$maxios('post', `${process.env.VUE_APP_API_URL}/itemTypeCategories`, {
        name: this.name,
        description: this.description,
        attributeRequirements: []
      })
          .then(() => {
            this.saving = false
            this.showModal = false
            this.callback()
          })
          .catch(() => {
            this.formError = true
            this.saving = false
          })
    }
  }
}
</script>

<style scoped>

</style>