var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"container",staticClass:"ripple-outer",on:{"mousedown":_vm.addRipple}},[_c('transition-group',{staticClass:"ripples",attrs:{"name":"grow","tag":"div"}},_vm._l((_vm.ripples),function(ripple){return _c('div',{key:ripple.id,staticClass:"ripple",style:({
          top: ripple.top,
          left: ripple.left,
          width: ripple.width,
          height: ripple.height,
          background: _vm.color
        })})}),0),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }