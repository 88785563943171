
<template>
  <div>
    <OverlayWithText :show="saving"></OverlayWithText>
    <div class="sub-page-header">
      <div class="container-fluid py-4 d-flex justify-content-between">
        <h3 class="page-title sm">Edit Template</h3>
        <div>
          <b-button :disabled="!needSaving" variant="light" size="sm"
                    class="mr-3 hop-btn" @click="reset()">Reset
          </b-button>
          <b-button :disabled="!needSaving" variant="light" size="sm"
                    class="hop-btn" @click="save()">Save
          </b-button>
        </div>
      </div>
    </div>
    <div class="container-fluid" style="margin-top: -4.5rem;">
      <b-card class="edit-container" v-if="!loading">
        <b-form @submit="onSubmit">
          <b-form-group label="Name" label-for="name-input">
            <b-form-input
                id="name-input"
                v-model="template.name"
                :state="validName"
                required></b-form-input>
            <b-form-invalid-feedback :state="validName">
              Name cannot be empty.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Description" label-for="description-input">
            <b-form-textarea
                id="description-input"
                v-model="template.description"
                :state="validDescription"
                required></b-form-textarea>
            <b-form-invalid-feedback :state="validDescription">
              Name cannot be empty.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Item Type to Produce">
            <SingleSearchSelect
                ref="categorySearchSelect"
                :current-value="template.typeToProduce.name"
                :provider="searchItemType"
                @itemChanged="itemChanged"
            ></SingleSearchSelect>
          </b-form-group>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import OverlayWithText from "@/components/util/OverlayWithText";
import SingleSearchSelect from "@/components/util/SingleSearchSelect";


export default {
  name: "ProcessTemplateEdit",
  components: {
    SingleSearchSelect,
    OverlayWithText
  },
  data() {
    return {
      saving: false,
      loading: true,
      template: {},
      templateCopy: '',
    }
  },
  mounted() {
    this.$maxios('get', 'assemblyProcesses/templates/' + this.$route.params.id).then((response) => {
      this.template = response.data
      this.templateCopy = JSON.stringify(response.data)
      this.loading = false
    }).catch(() => {
      this.$alerts.setAlertMessage('The template could not be retrieved.', 2, 'danger')
    })
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
    },
    searchItemType(query, callback) {
      this.$maxios('get', `itemTypes?producible=true&q=${query}`).then(response => {
        const items = response.data.content.map(i => {
          return {value: i, text: i.name}
        })
        callback(items)
      }).catch(() => {
        this.$alerts.setAlertMessage('Something went wrong searching for item types.', 10, 'danger')
        callback([])
      })
    },
    itemChanged(item) {
      console.log(item)
      this.template.typeToProduce = item
    },
    reset() {
      this.template = JSON.parse(this.templateCopy)
    },
    save() {
      this.saving = true
      const id = this.$route.params.id
      this.$maxios('patch', `assemblyProcesses/templates/${id}`, this.template).then(() => {
        this.$router.replace({name: 'product-management'})
      }).catch(() => {
        this.saving = false
        this.$alerts.setAlertMessage('An error occurred try again later.', 2, 'danger')
      })
    }
  },
  computed: {
    validName: function () {
      return this.template && this.template.name !== null && this.template.name.length > 0 &&
          this.template.name !== ''
    },
    validDescription: function () {
      return this.template && this.template.description !== null && this.template.description.length > 0 &&
          this.template.description !== ''
    },
    needSaving: function () {
      return JSON.stringify(this.template) !== this.templateCopy
    }
  }
}
</script>

<style scoped>
.edit-container {
  padding: 0.75rem 1.25rem;
  color: #222222;
}

</style>
