<template>
  <b-modal v-model="showModal" ok-only size="md" :title="title" body-class="no-padding">
    <div class="d-flex justify-content-center">
      <div class="qr-container">
        <qrcode-stream :track="paintOutline" @decode="onDecode" @init="onInit">
          <div v-if="error" class="validation-failure">
            {{ error }}
          </div>

          <div v-if="pending === true" class="validation-pending">
            Validation in progress...
          </div>
          <div v-if="pending && pending !== true" class="validation-success">
            {{ pending }}
          </div>

          <div class="target"></div>
        </qrcode-stream>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: "QRCodeScannerModal",
  props: {
    title: {
      type: String,
      default: "Item Type Scanner"
    },
  },
  data() {
    return {
      showModal: false,
      result: null,
      error: null,
      pending: false,
      callback: null
    }
  },
  methods: {
    attemptScan(callback) {
      this.callback = callback
      this.pending = false
      this.error = null
      this.result = null
      this.showModal = true
    },
    onDecode(result) {
      this.pending = true
      this.$maxios('get', `itemTypes/${result}`).then(response => {
        this.pending = true
        setTimeout(() => {
          this.pending = "Found: " + response.data.name
          setTimeout(() => {
            this.pending = false
            this.callback(response.data)
            this.showModal = false
          }, 500)
        }, 500)
      }).catch(err => {
        this.pending = false
        if (err.response && err.response.data && err.response.data.message) {
          this.error = err.response.data.message
          setTimeout(() => {
            this.error = null
          }, 1500)
        } else {
          this.$alerts.setAlertMessage('Invalid QR Code.', 2, 'danger')
        }
      })
      this.result = result
    },
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const {x, y} of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },

    async onInit(promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permission"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        } else if (error.name === 'InsecureContextError') {
          this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },
  }
}
</script>

<style scoped>

.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, .8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.validation-success {
  color: green;
}

.validation-failure {
  color: red;
}

.target {
  position: absolute;
  width: 100%;
  height: 100%;

  border: 20px solid rgba(255, 255, 255, .75);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.qr-container {
  width: 500px;
  height: 400px;
  /*border: 1px solid black*/
}

</style>