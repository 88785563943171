<template>
  <div>
    <div class="sub-page-header">
      <div class="container-fluid py-4 d-flex justify-content-between">
        <h3 class="page-title sm">Process Overview</h3>
      </div>
    </div>
    <div v-if="process && status && !loading" class="container-fluid " style="margin-top: -4.5rem;">
      <b-row>
        <b-col cols="12">
          <b-card class="pl-3">
            <b-row>
              <b-col cols="9" class="py-2">
                <div style="border-bottom: 2px #e8e8e8 solid" class="pb-3 mb-2">
                  <h5>Status</h5>
                  <b-progress :max="status.totalChildProcesses">
                    <b-progress-bar :value="status.childProcessesComplete"
                                    :label="`${((status.childProcessesComplete / status.totalChildProcesses) * 100).toFixed(0)}%`"></b-progress-bar>
                  </b-progress>
                </div>
                <h4>Tasks</h4>
                <RecursiveSubProcess
                    :process="process"
                    :nodes="process.childrenIds"
                ></RecursiveSubProcess>
              </b-col>

              <!-- Side bar right -->
              <b-col cols="3">
                <div class="py-2 sidebar">
                  <div class="block">
                    <div class="block-title">Location</div>
                    <div class="block-value">{{ process.location.name }}</div>
                  </div>
                  <div class="block">
                    <div class="block-title">Labels</div>
                    <div class="block-value">
                      {{ process.typeToProduce.tags.join(', ') }}
                    </div>
                  </div>
                  <div class="block">
                    <div class="block-title">Categories</div>
                    <div class="block-value">
                      {{ process.typeToProduce.categories.join(', ') }}
                    </div>
                  </div>
                  <div class="block">
                    <div class="block-title">Manuals</div>
                    <div v-if="manualAttributes.length === 0">No manuals found.</div>
                    <div v-else>
                      <a :href="manual.href" v-for="manual of manualAttributes" :key="manual.name">
                        {{ manual.name }}
                      </a>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <OverlayWithText :show="loading" text="Loading"></OverlayWithText>
  </div>
</template>

<script>
import OverlayWithText from "@/components/util/OverlayWithText";
import RecursiveSubProcess from "@/components/routes/processes/RecursiveSubProcess";

export default {
  name: "Process",
  components: {RecursiveSubProcess, OverlayWithText},
  data() {
    return {
      process: null,
      loading: true,
      status: null,
      attributeValues: null
    }
  },
  mounted() {
    this.$maxios('get', `assemblyProcesses/${this.$route.params.id}`).then(response => {
      this.process = response.data
      this.$maxios('get', `/assemblyProcesses/${this.$route.params.id}/progress`).then(progressResponse => {
        this.status = progressResponse.data
        // Adding this process to the total.
        this.status.childProcessesComplete += this.process.completed ? 1 : 0
        this.status.totalChildProcesses += 1
        this.$maxios('get', `/assemblyProcesses/${this.$route.params.id}/assignedUsers`).then(usersResponse => {
          this.process.assignedUsers = usersResponse.data
          this.loading = false
        })
      })
      this.$maxios('get', `itemTypes/${this.process.typeToProduceId}`).then(itemTypeResponse => {
        this.attributeValues = itemTypeResponse.data.attributeValues
      })
    })
  },
  computed: {
    manualAttributes: function () {
      const regex = /\d*([mM][aA][nN][uU][aA][lL])\d*/g;
      const manuals = []
      if (this.attributeValues === null) return manuals

      for (const attribute of this.attributeValues) {
        if (attribute.attribute.name.match(regex) !== null && attribute.value !== null) {
          manuals.push({
            name: attribute.attribute.name,
            href: attribute.value
          })
        }
      }
      return manuals
    }
  }
}
</script>

<style scoped>
.sidebar {
  border-left: 1px solid lightgray;
  background: #fafafa;
  height: 100%;
  padding: 0 20px;
}

.block {
  padding: 16px 0;
  border-bottom: 1px solid #e8e8e8;
}

.block:last-of-type {
  border-bottom: none;
}

.block-title {
  margin-bottom: 4px;
  font-weight: 600;
}
</style>