<template>
  <Table title="Process Templates" :per-page="6" api-url="/assemblyProcesses/templates"
         :fields="processTemplatesFields"
         overview-link-key="id"
         edit-link-route-name="edit-process-templates"
         delete-required-rights="erp_processes_templates_remove"
         edit-required-rights="erp_processes_templates_update"
         deletable
         searchable
  ></Table>
</template>

<script>
import Table from "@/components/routes/Table";

export default {
  name: "Templates",
  components: {Table},
  data() {
    return {
      processTemplatesFields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'typeToProduce.name',
          label: 'Item Type',
          sortable: false
        },
        'Options'
      ]
    }
  },
}
</script>

<style scoped>

</style>