<template>
  <a v-if="file" :href="'data:' + file.mimeType + ';base64,' + file.base64EncodedContent"
     :download="file.name" rel="noopener noreferrer" target="_blank">
    Download File ({{file.name}})
  </a>
</template>

<script>
export default {
  name: "LazyLoadedFile",
  props: {
    id: {
      type: Number
    },
  },
  data() {
    return {
      file: null
    }
  },
  beforeMount() {
    let config = {headers: {Accept: `application/json`}}
    this.$maxios('get', `/files/${this.id}`, config).then(fileResponse => {
      this.file = fileResponse.data
    })
  }
}
</script>

<style scoped>

</style>