<template>
  <b-modal v-model="showModal" body-class="no-padding">
    <template #modal-footer style="display: none">
      <small class="text-muted">Select Template</small>
    </template>
    <div>
      <input @keyup="search()" class="search-input"
             v-model="query" type="text" placeholder="Search Templates">
    </div>
    <b-list-group flush>
      <b-list-group-item v-for="(template, index) of templates" class="clickable"
                         :key="index" @click="itemSelected(template)">
        <strong>{{template.name}}</strong> -
        <small class="text-muted">{{template.typeToProduce.name}}</small>
      </b-list-group-item>
    </b-list-group>
  </b-modal>
</template>

<script>
export default {
  name: "SelectTemplateModal",
  data() {
    return {
      showModal: false,
      templates: [],
      callback: null,
      query: ''
    }
  },
  methods: {
    show(callback) {
      this.$maxios('get', '/assemblyProcesses/templates').then(response => {
        this.query = ''
        this.callback = callback
        this.templates = response.data.content
        this.showModal = true
      })
    },
    search() {
      this.$maxios('get', `/assemblyProcesses/templates?q=${this.query}`).then(response => {
        this.templates = response.data.content
      })
    },
    itemSelected(template) {
      this.callback(template)
      this.showModal = false
    }
  },
}
</script>

<style scoped>
.search-input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #dfdfdf;
  padding: 0.75rem 1.25rem;
  outline: none;
}
.search-input:focus {
  outline: none;
}
</style>
