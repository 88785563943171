<template>
  <div>
    <Table
        title="Roles"
        :per-page="10"
        :override-api-url="authApiUrl"
        api-url="/roles"
        :fields="fields"
        overview-link-key="name"
        edit-link-route-name="edit-role"
        :action-buttons="actionButtons"
        ref="roleTable"
        deletable
        edit-required-rights="roles_add_right"
        delete-required-rights="roles_remove"
    >
    </Table>
    <AddRoleModal ref="roleModal"></AddRoleModal>
  </div>
</template>

<script>
import Table from "@/components/routes/Table";
import AddRoleModal from "@/components/routes/administration/modals/AddRoleModal";

export default {
  name: "RolesTab",
  components: {AddRoleModal, Table},
  data() {
    return {
      authApiUrl: process.env.VUE_APP_AUTH_API_URL,
      fields: [
        {
          key: 'name'
        },
        'options'
      ],
      actionButtons: [
        {
          title: 'Add Role',
          callback: () => this.addRole(),
          requiredRight: 'roles_create'
        }
      ]
    }
  },
  methods: {
    addRole() {
      this.$refs.roleModal.show(() => {
        this.$refs.roleTable.refresh()
      })
    }
  },
}</script>

<style scoped>

</style>