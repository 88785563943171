<template>
  <div>
    <div class="sub-page-header">
      <div class="container-fluid py-4 d-flex justify-content-between">
        <h3 class="page-title">Processes</h3>
        <router-link :to="{ name: 'new-process' }">
          <b-button v-requires-right:[_rightsKey]="'erp_processes_create'" variant="light" size="sm" class="hop-btn">Add</b-button>
        </router-link>
      </div>
    </div>
    <div class="container-fluid" style="margin-top: -4.5rem;">
      <b-row>
        <b-col>
          <Table
              title="Top Level Processes"
              small
              :page-options="[10,25,50,100]"
              api-url="/assemblyProcesses"
              :fields="fields"
              overview-link-key="id"
              overview-link-route-name="process"
              overview-required-rights="erp_processes_assigned-users_get"
              delete-info-key="typeToProduce.name"
              deletable
              delete-required-rights="erp_processes_remove"
          ></Table>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import '@/assets/styles/layout.css'
import Table from "@/components/routes/Table"
import {rightUpdateFunctions} from "@/mixins/util";

export default {
  name: "Processes",
  components: {Table},
  mixins: [rightUpdateFunctions],
  data: function () {
    return {
      fields: [
        {
          key: 'id',
          sortable: true
        },
        {
          key: 'status',
          label: 'status',
          sortable: false,
          formatter: this.statusFormatter
        },
        {
          key: 'typeToProduce.name',
          label: 'name',
          sortable: false
        },
        {
          key: 'location.name',
          sortable: false,
          label: 'Location'
        },
        "Options"
      ]
    }
  },
  methods: {
    statusFormatter(element) {
      switch (element) {
        case 'NOT_STARTED':
          return 'Not yet started'
        case 'COMPLETE':
          return 'Finished'
        case 'IN_PROGRESS':
          return 'In progress'
      }
      return '-'
    }
  }
}
</script>

<style scoped></style>