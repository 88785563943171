<template>
  <div v-if="show" class="disabled-page-overlay">
    <div class="my-auto">
      <h2 style="color: #4c58dd;">{{ text }}</h2>
      <div class="d-flex justify-content-center">
        <b-spinner></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OverlayWithText",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: 'Saving'
    }
  }
}
</script>

<style scoped>
.disabled-page-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.2);
  z-index: 99999;
  display: flex;
  justify-content: center;
}
</style>