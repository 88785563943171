<template>
  <div>
    <OverlayWithText :show="saving"></OverlayWithText>
    <div class="sub-page-header">
      <div class="container-fluid py-4 d-flex justify-content-between">
        <h3 class="page-title sm">Edit {{ roleName }}</h3>
        <div>
          <b-button variant="light" size="sm"
                    class="mr-3 hop-btn" @click="deleteRole()">Delete
          </b-button>
        </div>
      </div>
    </div>
    <div class="container-fluid" style="margin-top: -4.5rem;">
      <b-card class="edit-container" v-if="!loading">
        <b-row>
          <b-col cols="5">
            <b-card header="Available rights">
              <b-list-group flush>
                <div class="d-flex justify-content-center p-1">
                  <b-pagination class="mb-0" size="sm" pills :total-rows="totalRights"
                                v-model="currentPage" :per-page="13"></b-pagination>
                </div>
                <b-list-group-item class="p-0">
                  <label for="search-1" class="m-0"></label>
                  <input id="search-1" v-model="query"
                         placeholder="Search.." @keyup="searchAvailableRights()"
                         class="clean-search" style="padding: 5px 20px">
                </b-list-group-item>
                <b-list-group-item v-for="right of rightsCache" :key="right.name"
                                   @click="setSelected(right, 'selectedAddableRight')"
                                   class="clickable selectable-list-item d-flex justify-content-between"
                                   style="padding: 8px 20px">
                  {{ right.name }}
                  <div class="my-auto float-right">
                    <b-icon icon="check" scale="1.5" v-if="right.name === selectedAddableRight"></b-icon>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
          <b-col cols="2" class="d-flex flex-column justify-content-center" style="min-height: 300px">
            <b-button variant="success" size="sm" :disabled="!selectedAddableRight" @click="addRight()" class="mb-2">
              Add
            </b-button>
          </b-col>
          <b-col cols="5">
            <b-card header="Added rights">
              <b-list-group flush>
                <b-list-group-item v-for="right of roleRights" :key="right.name"
                                   class="selectable-list-item" style="padding: 8px 20px">
                  {{ right.name }}
                  <div class="my-auto float-right clickable">
                    <b-icon icon="trash" scale="1" @click="deleteRight(right)"></b-icon>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import OverlayWithText from "@/components/util/OverlayWithText";

export default {
  name: "RoleEdit",
  components: {
    OverlayWithText
  },
  data() {
    return {
      saving: false,
      loading: true,
      roleName: this.$route.params.id,
      roleRights: [],
      selectedRoleRight: null,
      rightsCache: [],
      selectedAddableRight: null,
      roleRightsCopy: '',
      query: '',
      currentPage: 1,
      totalRights: 0,
      authApiUrl: process.env.VUE_APP_AUTH_API_URL
    }
  },
  watch: {
    currentPage: function () {
      this.searchAvailableRights()
    }
  },
  mounted() {
    this.fetchRights()
  },
  methods: {
    fetchRights () {
      const url = `${this.authApiUrl}/roles/${this.$route.params.id}/rights`
      this.$maxios('get', url).then((response) => {
        this.roleRights = response.data
        this.roleRightsCopy = JSON.stringify(response.data)
        this.loading = false
      }).catch(() => {
        this.$alerts.setAlertMessage(`The role ${this.roleName} could not be retrieved.`, 2, 'danger')
      })
      this.searchAvailableRights()
    },
    searchAvailableRights() {
      const url = `${this.authApiUrl}/rights?size=13&q=${this.query}&page=${this.currentPage - 1}`
      this.$maxios('get', url)
          .then(response => {
            this.rightsCache = response.data.content
            this.totalRights = response.data.totalElements
            this.selectedAddableRight = null
          })
    },
    deleteRole() {
      this.$maxios('delete', `${this.authApiUrl}/roles/${this.$route.params.id}`).then(() => {
        this.$router.replace({name: 'administration-roles'})
      }).catch(() => {
        this.$alerts.setAlertMessage('An error occurred try again later.', 2, 'danger')
      })
    },
    save() {
      this.saving = true
      const id = this.$route.params.id
      this.$maxios('patch', `roles/${id}/rights`, this.roleRights).then(() => {
        this.$router.replace({name: 'administration-roles', params: {id: id}})
      }).catch(() => {
        this.saving = false
        this.$alerts.setAlertMessage('An error occurred try again later.', 2, 'danger')
      })
    },
    setSelected(right, type) {
      this[type] = this[type] === right.name ? null : right.name
    },
    /**
     * Will add the selected right, if any.
     */
    addRight() {
      this.$maxios('post', `${this.authApiUrl}/roles/${this.$route.params.id}/rights`, [
        this.selectedAddableRight
      ])
          .then(() => {
            this.fetchRights()
          }).catch(() => {
            this.$alerts.setAlertMessage('An error occurred try again later.', 2, 'danger')
          })
    },
    /**
     * Will remove the selected right, if any.
     */
    deleteRight(right) {
      const url = `${this.authApiUrl}/roles/${this.$route.params.id}/rights/${right.name}`
      this.$maxios('delete', url)
          .then(() => {
            this.fetchRights()
          }).catch(() => {
        this.$alerts.setAlertMessage('An error occurred try again later.', 2, 'danger')
      })
    }
  },
  computed: {
    needSaving: function () {
      return JSON.stringify(this.roleRights) !== this.roleRightsCopy
    }
  }
}
</script>

<style scoped>
.edit-container {
  padding: 0.75rem 1.25rem;
  color: #222222;
}

.selectable-list-item:hover {
  background: #f3f5f6;
  user-select: none;
}

.clean-search {
  width: 100%;
}

.clean-search, .clean-search:focus, .clean-search:hover {
  border: none !important;
}
</style>