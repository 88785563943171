<template>
  <div>
    <div>
      <div class="sub-page-header">
        <div class="container-fluid py-4 d-flex justify-content-between">
          <h3 class="page-title">Product Management</h3>
          <div class="text-center">
            <b-dropdown id="dropdown-right" right text="Add" variant="light" size="sm" class="mt-2" no-caret
                        v-require-one-of-rights:[_rightsKey]="'erp_processes_templates_create,erp_item-types_attributes_create,erp_tags_create,erp_stock_locations_create,erp_item-types_create,erp_item-types_categories_create'">
              <b-dropdown-item-button v-requires-right:[_rightsKey]="'erp_item-types_create'"
                  v-on:click="openProductTypeModal">Item type</b-dropdown-item-button>
              <b-dropdown-item-button v-requires-right:[_rightsKey]="'erp_item-types_categories_create'" v-on:click="openCategoryModal">Category</b-dropdown-item-button>
              <b-dropdown-item-button v-requires-right:[_rightsKey]="'erp_stock_locations_create'" v-on:click="openLocationModal">Location</b-dropdown-item-button>
              <b-dropdown-item-button v-requires-right:[_rightsKey]="'erp_tags_create'" v-on:click="openTagModal">Tag</b-dropdown-item-button>
              <b-dropdown-item-button v-requires-right:[_rightsKey]="'erp_item-types_attributes_create'" v-on:click="openAttributeModal">Attribute</b-dropdown-item-button>
              <b-dropdown-item-button v-requires-right:[_rightsKey]="'erp_processes_templates_create'" v-on:click="openProcessTemplateModal">Process Template</b-dropdown-item-button>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" style="margin-top: -4.5rem;">
      <b-card title="Card Title" no-body>
        <b-card-header header-tag="nav">
          <b-nav card-header tabs>
            <b-nav-item ref="itemTypes" v-requires-right:[_rightsKey]="'erp_item-types_get'" :to="{ name: 'product-management-item-types' }" exact exact-active-class="active">Item Types</b-nav-item>
            <b-nav-item ref="categories" v-requires-right:[_rightsKey]="'erp_item-types_categories_get'" :to="{ name: 'product-management-categories' }" exact exact-active-class="active">Categories</b-nav-item>
            <b-nav-item ref="templates" v-requires-right:[_rightsKey]="'erp_processes_templates_get'" :to="{ name: 'product-management-templates' }" exact exact-active-class="active">Process Templates</b-nav-item>
          </b-nav>
        </b-card-header>

        <b-card-body>
          <router-view class="no-border white-header"></router-view>
        </b-card-body>
      </b-card>

      <b-row class="mt-4">
        <b-col md="12" lg="4">
          <Table title="Locations" api-url="/stockLocations"
                 :fields="locationFields"
                 overview-link-key="id"
                 deletable
                 edit-link-route-name="edit-stock-locations"
                 delete-required-rights="erp_stock_locations_remove"
                 edit-required-rights="erp_stock_locations_update"
                 v-requires-right:[_rightsKey]="'erp_stock_locations_get'"
          ></Table>
        </b-col>
        <b-col md="12" lg="4">
          <Table title="Tags" api-url="/tags"
                 :fields="tagsFields"
                 overview-link-key="name"
                 deletable
                 delete-required-rights="erp_tags_remove"
                 v-requires-right:[_rightsKey]="'erp_tags_get'"
          ></Table>
        </b-col>
        <b-col md="12" lg="4">
          <Table title="Attributes" api-url="/itemTypeAttributes"
                 :fields="tagsFields"
                 overview-link-key="id"
                 edit-link-route-name="edit-attributes"
                 delete-required-rights="erp_item-types_attributes_remove"
                 edit-required-rights="erp_item-types_attributes_update"
                 deletable
                 v-requires-right:[_rightsKey]="'erp_item-types_attributes_get'"
          ></Table>
        </b-col>
      </b-row>
    </div>
    <AddTagModal ref="tagModal"></AddTagModal>
    <AddItemTypeModal ref="itemTypeModal"></AddItemTypeModal>
    <AddLocationModal ref="locationModal"></AddLocationModal>
    <AddCategoryModal ref="categoryModal"></AddCategoryModal>
    <AddAttributeModal ref="attributeModal"></AddAttributeModal>
    <AddProcessTemplateModal ref="processTemplateModal"></AddProcessTemplateModal>
  </div>
</template>

<script>
import '@/assets/styles/layout.css'
import Table from '@/components/routes/Table'
import AddTagModal from "@/components/routes/productManagement/modals/AddTagModal";
import AddLocationModal from "@/components/routes/productManagement/modals/AddLocationModal";
import AddCategoryModal from "@/components/routes/productManagement/modals/AddCategoryModal";
import AddItemTypeModal from "@/components/routes/productManagement/modals/AddItemTypeModal";
import AddAttributeModal from "@/components/routes/productManagement/modals/AddAttributeModal";
import AddProcessTemplateModal from "@/components/routes/productManagement/modals/AddProcessTemplateModal";
import {rightUpdateFunctions} from "@/mixins/util";

export default {
  name: "ProductManagement",
  components: {
    AddProcessTemplateModal,
    AddAttributeModal,
    AddItemTypeModal,
    AddLocationModal,
    AddTagModal,
    AddCategoryModal,
    Table
  },
  mixins: [rightUpdateFunctions],
  data: function () {
    return {
      tagsFields: [
        {
          key: 'name',
          sortable: false
        },
        'Options'
      ],
      locationFields: [
        {
          key: 'name',
          sortable: false
        },
        'Options'
      ]
    }
  },
  methods: {
    openProductTypeModal () {
      this.$refs.itemTypeModal.show(this.refreshTables)
    },
    openCategoryModal () {
      this.$refs.categoryModal.show(this.refreshTables)
    },
    openTagModal () {
      this.$refs.tagModal.show(this.refreshTables)
    },
    openLocationModal () {
      this.$refs.locationModal.show(this.refreshTables)
    },
    openAttributeModal () {
      this.$refs.attributeModal.show(this.refreshTables)
    },
    openProcessTemplateModal () {
      this.$refs.processTemplateModal.show(this.refreshTables)
    },
    refreshTables () {
      this.$root.$emit('bv::refresh::table', 'data-table')
    }
  },
  mounted () {
    // We only wanna do this redirect when we are not in a sub route.
    if (this.$route.name !== 'product-management') return

    if (!this.$refs.itemTypes.hidden) {
      this.$router.replace({ name: 'product-management-item-types' })
      return
    }
    if (!this.$refs.categories.hidden) {
      this.$router.replace({ name: 'product-management-categories' })
      return
    }
    if (!this.$refs.templates.hidden) {
      this.$router.replace({ name: 'product-management-templates' })
      return
    }
    if (!this.$refs.auxiliary.hidden) {
      this.$router.replace({ name: 'product-management-auxiliary' })
    }
  }
}
</script>

<style scoped>
</style>