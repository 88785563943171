<template>
  <div class="search-container">
    <div @click="toggleSearch()" class="single-search-value-field">
      <span v-if="item && item !== ''">{{ item }}</span>
      <span class="text-muted" v-else>{{ placeholder }}</span>
      <b-icon v-if="!showResults" variant="info" small class="float-right"
              icon="caret-down-fill"
              style=" margin-top: 5px;"></b-icon>
      <b-icon v-else variant="info" small class="float-right" icon="caret-up-fill"
              style=" margin-top: 5px;"></b-icon>
      <b-icon v-if="item && item !== ''" variant="info" small class="float-right"
              icon="x" scale="1.4" @click="() => {reset(); toggleSearch()}"
              style="margin-top: 5px;"></b-icon>
    </div>
    <div v-if="showResults" class="search-results-container">
      <div class="internal-search">
        <input class="internal-search-input" @keyup="search()" @focusin="search()" ref="search"
               placeholder="Search..." v-model="query">
        <b-icon variant="info" small icon="search" class="my-auto ml-auto"></b-icon>
      </div>
      <div class="scroll-container">
        <div v-if="loading" class="d-flex justify-content-center py-1">
          <b-spinner variant="info" small class="my-auto"></b-spinner>
        </div>
        <div v-else class="search-result-element"
             v-for="(option, index) of options" :key="index"
             @click="itemClicked(option)">
          {{ option.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SingleSearchSelect",
  props: {
    provider: {
      type: Function || Array
    },
    currentValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Select'
    }
  },
  data() {
    return {
      item: '',
      loading: false,
      showResults: false,
      query: '',
      options: []
    }
  },
  methods: {
    toggleSearch() {
      this.showResults = !this.showResults

      if (this.showResults) {
        // Give some time to let the ref actually render.
        this.$nextTick(() => {
          this.$refs.search.focus()
        })
      }
    },
    search() {
      if (typeof this.provider === "function") {
        this.loading = true
        this.provider(this.query, i => this.setOptions(i))
      }
    },
    setOptions(options) {
      this.options = options
      this.loading = false
    },
    closeResults() {
      this.showResults = false
      this.query = ''
    },
    closeEvent(e) {
      if (!this.$el.contains(e.target)) {
        this.closeResults()
      }
    },
    reset() {
      this.item = null
      this.query = ''
      this.$emit('itemChanged', null)
    },
    itemClicked(item) {
      this.item = item.text
      this.showResults = false
      this.$emit('itemChanged', item.value)
    },
    setItemTextFromOutside(item) {
      this.item = item
    }
  },
  mounted() {
    this.item = this.currentValue
    document.addEventListener('click', this.closeEvent)
    this.search()
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeEvent)
  }
}
</script>

<style scoped>
.single-search-value-field {
  padding: 5px 10px;
  cursor: pointer;
  user-select: none;
  min-height: 38px;
}

.search-container {
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  height: 38px;
  position: relative;
  min-width: 250px;
}
.input-group-sm .search-container {
  height: 31px;
  background: white;
  font-size: 14px;
}

.search-container:hover {
  border-color: #dedede;
}

.search-container:focus-within {
  border-color: lightblue;
}

.internal-search, .internal-search:focus {
  border: none;
  outline: none !important;
  border-bottom: 1px solid lightblue;
  padding: 5px 10px;
  width: 100%;
  display: flex;
}

.internal-search-input, .internal-search-input:focus {
  border: none;
  outline: none !important;
}

.search-results-container {
  position: absolute;
  top: 34px;
  left: -1px;
  width: calc(100% + 2px);
  min-height: 5px;
  border: 1px solid lightblue;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  z-index: 9999;
}
.input-group-sm .search-results-container {
  top: 28px;
}

.scroll-container {
  min-height: 5px;
  max-height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}

.search-result-element {
  padding: 5px 10px;
}

.search-result-element:hover {
  background-color: #e6f2ff;
  cursor: pointer;
}
</style>