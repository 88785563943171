<template>
  <div>
    <OverlayWithText :show="saving"></OverlayWithText>
    <div class="sub-page-header">
      <div class="container-fluid py-4 d-flex justify-content-between">
        <h3 class="page-title sm">Edit Stock Location</h3>
        <div>
          <b-button :disabled="!needSaving" variant="light" size="sm"
                    class="mr-3 hop-btn" @click="reset()">Reset
          </b-button>
          <b-button :disabled="!needSaving" variant="light" size="sm"
                    class="hop-btn" @click="save()">Save
          </b-button>
        </div>
      </div>
    </div>
    <div class="container-fluid" style="margin-top: -4.5rem;">
      <b-card class="edit-container" v-if="!loading">
        <b-form @submit.prevent="">
          <b-form-group label="Name" label-for="name-input" description="The name of the warehouse.">
            <b-form-input
                id="name-input"
                v-model="stockLocation.name"
                :state="validName"
                required></b-form-input>
            <b-form-invalid-feedback :state="validName">
              Name cannot be empty.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import OverlayWithText from "@/components/util/OverlayWithText";


export default {
  name: "LocationEdit",
  components: {OverlayWithText},
  data() {
    return {
      saving: false,
      loading: true,
      stockLocation: {},
      stockLocationCopy: ''
    }
  },
  mounted() {
    this.$maxios('get', 'stockLocations/' + this.$route.params.id).then((response) => {
      this.stockLocation = response.data
      this.stockLocationCopy = JSON.stringify(response.data)
      this.loading = false
    }).catch(() => {
      this.$alerts.setAlertMessage('The location could not be retrieved.', 2, 'danger')
    })
  },
  methods: {
    reset() {
      this.stockLocation = JSON.parse(this.stockLocationCopy)
    },
    save() {
      this.saving = true
      const id = this.$route.params.id
      this.$maxios('patch', `stockLocations/${id}`, this.stockLocation).then(() => {
        this.$router.replace({name: 'product-management'})
      }).catch(() => {
        this.saving = false
        this.$alerts.setAlertMessage('An error occurred try again later.', 2, 'danger')
      })
    }
  },
  computed: {
    needSaving: function () {
      return JSON.stringify(this.stockLocation) !== this.stockLocationCopy
    },
    validName: function () {
      return this.stockLocation.name !== null && this.stockLocation.name !== ""
    }
  }
}
</script>

<style scoped>
.edit-container {
  padding: 0.75rem 1.25rem;
  color: #222222;
}
</style>