<template>
  <DropdownMenu small disabled-inside-click-close ref="dropdown-menu">
    <b-button size="sm" variant="light" slot="trigger"
              class="ml-2 my-auto" style="padding: 0 .5rem"
              @click="resetQuery()"
    >
      Edit
    </b-button>
    <template #content>
      <div class="dropdown-title">
        <div class="d-flex justify-content-center my-auto" style="width: 16px">
          <b-spinner v-if="loadingUsers" variant="info" small></b-spinner>
        </div>
        <div class="">Assign to</div>
        <b-icon class="my-auto clickable" icon="x" style="width: 16px"
                scale="1.5" @click="$refs['dropdown-menu'].forceClose()"></b-icon>
      </div>
      <div class="dropdown-select-container">
        <b-input class="search-box" type="text"
                 placeholder="Search users" v-model="query"
                 @keyup="getUsers()"
        ></b-input>
      </div>
      <div class="dropdown-select-container">
        <div style="font-size: 14px; font-weight: 600">Assignees</div>
        <div  v-for="user of process.assignedUsers" :key="user.id">
          <b-row class="py-1 clickable" @click="emitUserEvent('removeAssignedUser', user)">
            <b-col cols="2" class="my-auto">
              <b-icon class="" icon="check" scale="2"></b-icon>
            </b-col>
            <b-col cols="10">
              <div class="user-name">{{ user.fullName }}</div>
              <div class="text-muted user-username">@{{ user.username }}</div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="dropdown-select-container">
        <div v-for="user of filteredUsers" :key="user.id">
          <b-row class="py-1 clickable" @click="emitUserEvent('addAssignedUser', user)">
            <b-col cols="2"></b-col>
            <b-col cols="10">
              <div class="user-name">{{ user.fullName }}</div>
              <div class="text-muted user-username">@{{ user.username }}</div>
            </b-col>
          </b-row>
        </div>
      </div>
    </template>
  </DropdownMenu>
</template>

<script>
import DropdownMenu from "@/components/util/DropdownMenu";

export default {
  name: "UserSearchMultiSelect",
  components: {DropdownMenu},
  data() {
    return {
      query: '',
      users: [],
      loadingUsers: false
    }
  },
  props: {
    process: {
      type: Object
    },
  },
  methods: {
    emitUserEvent(event, user) {
      this.$emit(event, user)
      this.loadingUsers = true
      setTimeout(() => {
        this.$forceUpdate()
        this.getUsers()
      },500)
    },
    getUsers() {
      this.loadingUsers = true
      this.$maxios('get', `/assemblyProcesses/${this.process.id}/assignedUsers/candidates?q=${this.query}`).then(response => {
        this.users = response.data
        this.loadingUsers = false
      })
    },
    resetQuery() {
      this.query = ''
      this.getUsers()
    }
  },
  computed: {
    filteredUsers() {
      return this.users.filter((user) => {
        for (const assignedUser of this.process.assignedUsers) {
          if (user.id === assignedUser.id) {
            return false
          }
        }
        return true
      });
    }
  },
}
</script>

<style scoped>
.dropdown-title {
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  padding: 8px 12px 12px;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 10px;
}

.dropdown-select-container {
  padding: 0 10px 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dbdbdb;
}

.dropdown-select-container:last-of-type {
  border-bottom: none;
  margin-bottom: 0;
}

.search-box {
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  border-radius: 2px;
  padding: 0 7px;
}

.user-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.user-username {
  font-size: 14px;
  line-height: 16px;
}

</style>