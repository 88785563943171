<template>
  <div>
    <div class="sub-page-header">
      <div class="container-fluid py-4">
        <h3 class="page-title">Dashboard</h3>
      </div>
    </div>
    <div class="container-fluid" style="margin-top: -4.5rem;">
      <b-row>
        <b-col>
          <Table
              title="My tasks"
              api-url="/me/assignedProcesses"
              :fields="fields"
              searchable
              overview-link-key="id"
              overview-link-route-name="process"
              v-requires-right:[_rightsKey]="'erp_processes_assigned-users_get'"
          ></Table>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Table from "@/components/routes/Table";

export default {
  name: "Dashboard",
  components: {Table},
  data() {
    return {
      assignedProcesses: null,
      fields: [
        {
          key: 'typeToProduce.name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'location.name',
          label: 'Location',
          sortable: true
        },
        {
          key: 'status',
          sortable: false,
          formatter: this.statusFormatter
        },
        "Options"
      ]
    }
  },
  mounted() {
    this.$maxios('get', `me/assignedProcesses`).then(response => {
      this.assignedProcesses = response.data.content
    })
  },
  methods: {
    statusFormatter(element) {
      switch (element) {
        case 'NOT_STARTED':
          return 'Not yet started'
        case 'COMPLETE':
          return 'Finished'
        case 'IN_PROGRESS':
          return 'In progress'
      }
      return '-'
    }
  },
}
</script>

<style scoped>

</style>