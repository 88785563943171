<template>
  <div class="layout">
    <Header class="layout-header"></Header>
    <router-view class="layout-content"></router-view>
    <div class="layout-sidebar">
      <Sidebar class="sticky"></Sidebar>
    </div>
    <Alerts ref="alerts"></Alerts>
    <div class="version-container">
      <small class="text-muted">Version: {{ version }}</small><br>
      <small class="text-muted">Designed and Developed By
        <a style="color: inherit; text-decoration: underline" href="https://www.gyrobian.nl">Gyrobian</a>
      </small>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/sidebar/Sidebar'
import Header from '@/components/header/Header'
import Alerts from '@/components/util/Alert'
import Vue from 'vue'
import {version} from '../../package.json';

export default {
  name: 'Inventorizor',
  components: {
    Header,
    Sidebar,
    Alerts
  },
  data() {
    return {
      version: version + process.env.VUE_APP_FRONT_END_VERSION_APPEND
    }
  },
  mounted () {
    Vue.prototype.$alerts = this.$refs.alerts
  }
}
</script>

<style>
html {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.layout-header {
  grid-area: header;
  background-color: #5e72e4;
}
.layout-content {
  grid-area: main;
  background-color: #f8f9fe;
}
.layout-sidebar {
  grid-area: sidebar;
  background-color: #ffffff;
}
.sticky {
  position: sticky;
  top: 0;
}

body {
  margin: 0;
}

.layout {
  position: relative;
  display: grid;
  height: 100vh;
  grid-template-columns: 250px auto;
  grid-template-rows: 80px auto 55px;
  grid-template-areas:
    "sidebar header"
    "sidebar main"
    "version main"
}

a:hover {
  text-decoration: none;
}
.version-container {
  grid-area: version;
  padding-left: 10px;
}

</style>
