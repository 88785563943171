<template>
  <div>
    <Brand></Brand>
    <div class="mt-3"></div>
    <router-link :to="route.route"
                 v-for="route of routes"
                 :key="route.route"
                 class="router-element"
                 v-require-one-right-group:[_rightsKey]="route.rights"
    >
      <ripple class="nav-item">
        <b-icon :icon="route.icon" aria-hidden="true" variant="secondary"></b-icon>
        <span class="pl-2 nav-item-text">{{ route.name }}</span>
      </ripple>
    </router-link>
  </div>
</template>

<script>
import Brand from '@/components/sidebar/Brand'
import Ripple from '@/components/util/Ripple';
import {rightUpdateFunctions} from "@/mixins/util";

const generalPMRights = 'erp_tags_get,erp_stock_locations_get,erp_item-types_attributes_get'

export default {
  name: "Sidebar",
  components: {
    Ripple,
    Brand
  },
  mixins: [rightUpdateFunctions],
  data: function () {
    return {
      routes: [
        {
          route: '/',
          name: 'Dashboard',
          icon: 'house-fill',
          rights: ''
        },
        {
          route: '/processes',
          name: 'Processes',
          icon: 'card-checklist',
          rights: 'erp_processes_get'
        },
        {
          route: '/warehouse',
          name: 'Warehouse',
          icon: 'bookshelf',
          rights: 'erp_item-types_get,erp_stock_locations_get,erp_stock_get'
        },
        {
          route: '/product-management',
          name: 'Product Management',
          icon: 'grid-fill',
          rights: `erp_processes_templates_get,${generalPMRights};erp_item-types_get,${generalPMRights};erp_processes_templates_get,${generalPMRights}`
        },
        {
          route: '/administration',
          name: 'Administration',
          icon: 'people-fill',
          rights: 'users_get;services_get;roles_get;rights_get'
        }
        // {
        //   route: '/settings',
        //   name: 'Settings',
        //   icon: 'gear-fill',
        //   rights: ''
        // }
      ]
    }
  }
}
</script>

<style scoped>
.nav-item {
  display: flex;
  align-items: center;
  color: inherit;
  outline: none;
  border-radius: 5px;
  margin: .25em .5em;
  padding: .75em .5em;
  /*mx-2 px-3 py-2*/

}
.router-element:focus {
  outline: none;
}
.router-link-exact-active .nav-item, .nav-item:hover {
  background: #e1e5fd;
  color: #FFF;
  text-decoration: none;
}

.nav-item-text {
  color: #2c3e50;
}

a:hover {
  text-decoration: none;
}

</style>