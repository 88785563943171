<template>
  <div>
    <div class="sub-page-header">
      <div class="container-fluid py-4 d-flex justify-content-between">
        <h3 class="page-title sm">Item Type Overview</h3>
        <div v-if="itemType.id !== undefined">
          <router-link :to="{ name: 'edit-item-type', params: { id: itemType.id } }">
            <b-button variant="light" size="sm" class="hop-btn">Edit</b-button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="container-fluid" style="margin-top: -4.5rem;">
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-card class="overview-container">
            <b-skeleton-table
                :rows="5"
                :columns="2"
                :table-props="{ bordered: true }"
            ></b-skeleton-table>
          </b-card>
        </template>
        <b-card>
          <div v-if="!loading" class="overview-container">
            <div class="information-row">
              <div class="information-label">id</div>
              <div class="information-value">{{ itemType.id }}</div>
            </div>

            <div class="information-row">
              <div class="information-label">name</div>
              <div class="information-value">{{ itemType.name }}</div>
            </div>

            <div class="information-row">
              <div class="information-label">consumable</div>
              <div class="information-value">{{ itemType.consumable }}</div>
            </div>

            <div class="information-row">
              <div class="information-label">description</div>
              <div class="information-value">{{ itemType.description }}</div>
            </div>

            <div class="information-row">
              <div class="information-label">categories</div>
              <div class="information-value">{{ itemType.categories.join(', ') }}</div>
            </div>

            <div class="information-row">
              <div class="information-label">tags</div>
              <div class="information-value">{{ itemType.tags.join(', ') }}</div>
            </div>

            <div class="information-row">
              <div class="information-label">Attributes</div>
              <div class="information-value">
                <div v-for="attribute of itemType.attributeValues" :key="attribute.attribute.id">
                  <div><strong>{{ attribute.attribute.name }}</strong></div>
                  <template v-if="attribute.value !== null">
                    <template v-if="attribute.attribute.contentType === 'link'">
                      <a :href="attribute.value">{{ attribute.value }}</a>
                    </template>
                    <template
                        v-if="attribute.attribute.contentType === 'number' || attribute.attribute.contentType === 'text'">
                      <div>{{ attribute.value }}</div>
                    </template>
                    <template v-if="attribute.attribute.contentType === 'image' && attribute.attribute.value !== null">
                      <LazyLoadedImage :id="attribute.value.id"></LazyLoadedImage>
                    </template>
                    <template v-if="attribute.attribute.contentType === 'document' && attribute.attribute.value !== null">
                      <LazyLoadedFile :id="attribute.value.id"></LazyLoadedFile>
                    </template>
                  </template>
                  <template v-else>-</template>
                </div>
              </div>
            </div>

            <div class="information-row">
              <div class="information-label">required subtypes</div>
              <div class="information-value">
                <div v-for="child of itemType.requiredChildTypes" :key="child.itemTypeId">
                  {{ child.count }}x {{ child.itemTypeName }}
                </div>
              </div>
            </div>

            <div class="information-row">
              <div class="information-label">QR Code</div>
              <div class="information-value">
                <b-button v-if="qrCode === null" @click="fetchQRCodeForItemType(itemType)" size="sm" variant="primary">
                  Fetch QR Code
                </b-button>
                <template v-else>
                  <img :src="'data:image/png;base64,' + qrCode.image"
                       alt="qrCode" height="200" width="200">
                  <b-button size="sm" :href="'data:image/png;base64,' + qrCode.image" variant="primary"
                            :download="itemType.name + 'qr-code'" rel="noopener noreferrer" target="_blank">
                    Download Code
                  </b-button>
                </template>
              </div>
            </div>
          </div>
        </b-card>
      </b-skeleton-wrapper>

      <h3 class="page-title sm mt-3">Stock Quantities of '{{ itemType.name }}'</h3>
      <b-row class="mt-3">
        <b-col cols="6">
          <b-card>
            <template #header>
              <h5>Available stock</h5>
            </template>
            <div>
              <apexchart type="area" :options="options" :series="availableSeries"></apexchart>
            </div>
          </b-card>
        </b-col>
        <b-col cols="6">
          <b-card>
            <template #header>
              <h5>Reserved stock</h5>
            </template>
            <div>
              <apexchart type="area" :options="options" :series="reservedSeries"></apexchart>
            </div>
          </b-card>
        </b-col>
      </b-row>

    </div>
  </div>
</template>

<script>

import '@/assets/styles/table-card.css'
import LazyLoadedImage from "@/components/util/LazyLoadedImage";
import LazyLoadedFile from "@/components/util/LazyLoadedFile";

export default {
  name: "ItemTypeOverview",
  components: {LazyLoadedFile, LazyLoadedImage},
  data: function () {
    return {
      itemType: {},
      loading: true,
      qrCode: null,
      options: {
        chart: {
          type: 'area',
          height: 300,
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        xaxis: {
          type: 'datetime',
        },
        legend: {
          horizontalAlign: 'left'
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100]
          }
        }
      },
      availableSeries: [],
      reservedSeries: []
    }
  },
  mounted() {
    this.$maxios('get', 'itemTypes/' + this.$route.params.id).then((response) => {
      this.itemType = response.data
      this.$maxios('get', 'stockItems/data?itemTypeId=' + this.$route.params.id).then((response) => {
        this.series = []
        this.availableSeries.push({
          name: 'amount',
          data: response.data.map(i => {
            return [new Date(i.createdAt).getTime(), i.amount]
          })
        })
        this.reservedSeries.push({
          name: 'amountReserved',
          data: response.data.map(i => {
            return [new Date(i.createdAt).getTime(), i.amountReserved]
          })
        })
        this.loading = false
      }).catch(() => {
        this.$alerts.setAlertMessage('The data for this item type could not be retrieved.', 10, 'danger')
        this.loading = true
      })
    }).catch(() => {
      this.$alerts.setAlertMessage('The itemType could not be retrieved.', 10, 'danger')
      this.loading = true
      this.$router.replace({name: 'notFound'})
    })
  },
  methods: {
    flatten(array) {
      return array.join(', ')
    },
    fetchQRCodeForItemType(itemType) {
      this.$maxios('get', `itemTypes/${itemType.id}/qr-code`).then(response => {
        console.log(response.data)
        this.qrCode = response.data
      })
    }
  }
}
</script>

<style scoped>
.overview-container {
  padding: 0.75rem 1.25rem;
  color: #222222;
}

.information-row {
  display: flex;
}

.information-value {
  width: 100%;
  padding: 0.75rem 1.25rem;
  border: 1px solid #727272;
  border-left: none;
}

.information-row:not(:last-of-type) .information-label,
.information-row:not(:last-of-type) .information-value {
  border-bottom: none !important;
}

pre {
  margin-bottom: 0;
}

.information-label {
  width: 200px;
  font-weight: 600;
  text-transform: capitalize;
  background-color: #e0dfdf;
  padding: 0.75rem 1.25rem;
  border: 1px solid #727272;
}

h5 {
  margin-bottom: 0;
}
</style>