import { render, staticRenderFns } from "./LocationEdit.vue?vue&type=template&id=dc85efa0&scoped=true"
import script from "./LocationEdit.vue?vue&type=script&lang=js"
export * from "./LocationEdit.vue?vue&type=script&lang=js"
import style0 from "./LocationEdit.vue?vue&type=style&index=0&id=dc85efa0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc85efa0",
  null
  
)

export default component.exports