import Vue from "vue";
import Vuex from 'vuex'
import auth from '@gyrobian/mux-auth-core';

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth
    }
})