<template>
  <div>
    <OverlayWithText :show="saving"></OverlayWithText>
    <div class="sub-page-header">
      <div class="container-fluid py-4 d-flex justify-content-between">
        <h3 class="page-title sm">Edit Category</h3>
        <div>
          <b-button :disabled="!needSaving" variant="light" size="sm"
                    class="mr-3 hop-btn" @click="reset()">Reset
          </b-button>
          <b-button :disabled="!needSaving" variant="light" size="sm"
                    class="hop-btn" @click="save()">Save
          </b-button>
        </div>
      </div>
    </div>
    <div class="container-fluid" style="margin-top: -4.5rem;">
      <b-card class="edit-container" v-if="!loading">
        <b-form @submit="onSubmit">
          <b-form-group label="Name" label-for="name-input">
            <b-form-input
                id="name-input"
                v-model="category.name"
                :state="validName"
                required></b-form-input>
            <b-form-invalid-feedback :state="validName">
              Name cannot be empty.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Description" label-for="description-input">
            <b-form-input
                id="description-input"
                v-model="category.description"
                :state="validDescription"
                required></b-form-input>
            <b-form-invalid-feedback :state="validDescription">
              Name cannot be empty.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Attribute Requirements">
            <MultipleSearchSelect
                ref="categorySearchSelect"
                :parent-items="formattedAttributes"
                :provider="search"
                @itemsChanged="attributesChanged"
            ></MultipleSearchSelect>
          </b-form-group>

          <b-form-group>
              <b-checkbox v-for="(attribute, index) of this.category.attributeRequirements"
                          :key="index" v-model="attribute.required" :name="attribute.attributeName">
                {{attribute.attributeName}}
              </b-checkbox>
            <b-form-text id="password-help-block">
              Attributes that will be required if a item type has this category and the checkbox is marked.
            </b-form-text>
          </b-form-group>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import OverlayWithText from "@/components/util/OverlayWithText";
import MultipleSearchSelect from "@/components/util/MultipleSearchSelect";


export default {
  name: "CategoryEdit",
  components: {
    OverlayWithText,
    MultipleSearchSelect
  },
  data() {
    return {
      saving: false,
      loading: true,
      category: {},
      categoryCopy: '',
    }
  },
  mounted() {
    this.$maxios('get', 'itemTypeCategories/' + this.$route.params.id).then((response) => {
      this.category = response.data
      this.categoryCopy = JSON.stringify(response.data)
      this.loading = false
    }).catch(() => {
      this.$alerts.setAlertMessage('The category could not be retrieved.', 2, 'danger')
    })
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
    },
    search (term, callback) {
      this.$maxios('get', `itemTypeAttributes?categories=&q=${term}`).then(response => {
        const items = response.data.content.map(i => { return { value: {
            attributeId: i.id,
            attributeContentType: i.contentType,
            attributeName: i.name,
            required: true
          }, text: i.name } })
        callback(items)
      })
    },
    reset() {
      this.category = JSON.parse(this.categoryCopy)
    },
    save() {
      this.saving = true
      const id = this.$route.params.id
      this.$maxios('patch', `itemTypeCategories/${id}`, this.category).then(() => {
        // this.$router.replace({name: 'overview-category', params: {id: id}})
        this.$router.replace({name: 'product-management', params: {id: id}})
      }).catch(() => {
        this.saving = false
        this.$alerts.setAlertMessage('An error occurred try again later.', 2, 'danger')
      })
    },
    attributesChanged (items) {
      this.category.attributeRequirements = items.map(i => i.value)
      console.log(this.category.attributeRequirements)
    }
  },
  computed: {
    validName: function () {
      return this.category && this.category.name !== null && this.category.name.length > 0 &&
          this.category.name !== ''
    },
    validDescription: function () {
      return this.category && this.category.description !== null && this.category.description.length > 0 &&
          this.category.description !== ''
    },
    needSaving: function () {
      return JSON.stringify(this.category) !== this.categoryCopy
    },
    formattedAttributes: function () {
      return this.category.attributeRequirements.map(i => { return { value: i, text: i.attributeName } })
    }
  }
}
</script>

<style scoped>
.edit-container {
  padding: 0.75rem 1.25rem;
  color: #222222;
}

</style>