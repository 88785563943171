<template>
  <Table
      title="Item Types" api-url="/itemTypes"
      searchable
      :fields="itemTypeFields"
      overview-link-key="id"
      overview-link-route-name="overview-item-type"
      edit-link-route-name="edit-item-type"
      delete-required-rights="erp_item-types_remove"
      edit-required-rights="erp_item-types_update"
      overview-required-rights="erp_stock_get"
      perPageCustomizable
      deletable
  ></Table>
</template>

<script>
import Table from "@/components/routes/Table";

export default {
  name: "ItemTypes",
  components: {Table},
  data() {
    return {
      itemTypeFields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'description',
          sortable: false
        },
        {
          key: 'categories',
          sortable: false,
          formatter: (i) => i.join(', ')
        },
        'Options'
      ]
    }
  },
}
</script>

<style scoped>

</style>