<template>
  <div>
    <Table
        title="Users"
        :per-page="10"
        :override-api-url="authApiUrl"
        api-url="/users"
        :fields="fields"
        searchable
        overview-link-key="username"
        per-page-customizable
        delete-info-key="username"
        deletable
        :action-buttons="actionButtons"
        ref="userTable"
        edit-link-route-name="edit-users"
        edit-required-rights="roles_get,services_get,users_update,users_get,users_remove_role,users_remove_service,users_add_role,users_add_service"
        delete-required-rights="users_remove"
    ></Table>
    <CreateUserInviteModal ref="createUserInviteModal"></CreateUserInviteModal>
  </div>
</template>

<script>
import Table from "@/components/routes/Table";
import CreateUserInviteModal from "@/components/routes/administration/modals/CreateUserInviteModal";

export default {
  name: "UsersTab",
  components: {CreateUserInviteModal, Table},
  data() {
    return {
      authApiUrl: process.env.VUE_APP_AUTH_API_URL,
      fields: [
        {
          key: 'username',
          sortable: true
        },
        {
          key: 'fullName'
        },
        {
          key: 'email'
        },
        'options'
      ],
      actionButtons: [
        {
          title: 'Invite User',
          callback: () => this.inviteUser(),
          requiredRight: 'users_create_invitation'
        }
      ]
    }
  },
  methods: {
    createUser() {
    },
    inviteUser() {
      this.$refs.createUserInviteModal.show(() => {
        this.$refs.userTable.refresh()
      })
    }
  },
}
</script>

<style scoped>

</style>