<template>
  <b-card>
    <template #header>
      <b-row>
        <b-col>
          <h6 class="text-uppercase text-muted mb-1" style="font-size: .625rem;">{{ subTitle }}</h6>
          <h5 class="h4 mb-0">{{ title }}</h5>
        </b-col>
        <b-col sm="4" class="ml-auto">
          <b-pagination v-if="rows/perPage>1"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        aria-controls="my-table"
                        align="right"
                        size="sm"
                        class="mb-0"
          ></b-pagination>
        </b-col>
      </b-row>

      <template v-if="searchable || perPageCustomizable">
        <div class="my-2" style="border-top: 2px solid #e4e4e4"></div>
        <b-row>
          <b-col :sm="filterable ? 3 : 6" v-if="searchable">
            <b-input-group size="sm">
              <template #append>
                <b-input-group-text style="background: white">
                  <b-icon icon="search"></b-icon>
                </b-input-group-text>
              </template>
              <b-input v-model="searchQuery" placeholder="Type to search.."></b-input>
            </b-input-group>
          </b-col>
          <b-col :sm="searchable ? 3 : 6" v-if="filterable">
            <b-input-group size="sm">
              <SingleSearchSelect :provider="filterSearchProvider" @itemChanged="changeFilter"></SingleSearchSelect>
            </b-input-group>
          </b-col>
          <b-col sm="6" class="ml-auto" v-if="perPageCustomizable">
            <b-form-group
                label="Per page"
                label-for="per-page-select"
                label-cols-sm="6"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
            >
              <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
      <template v-if="actionButtons.length > 0">
        <div class="my-2" style="border-top: 2px solid #e4e4e4"></div>
        <b-row>
          <b-col class="d-flex justify-content-end">
            <b-button v-for="(action, index) of actionButtons"
                      v-requires-right:[_rightsKey]="action.requiredRight ? action.requiredRight : ''" :key="index"
                      variant="primary" size="sm" class="ml-2" @click="action.callback()">{{ action.title }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </template>

    <b-table class="mb-0"
             :busy="isBusy"
             id="data-table"
             :filter="searchQuery"
             :items="provider"
             :fields="fields"
             :per-page="perPage"
             :current-page="currentPage"
             thead-class="text-uppercase text-muted head-style"
             hover
             @row-clicked="(row) => $emit('row-clicked', row)"
             :small="small"
             :tbody-tr-class="clickableRow ? 'clickable' : 's'"
    >
      <template #cell(options)="row" v-if="overviewLinkKey">
        <div class="d-flex justify-content-center">
          <router-link v-require-all-rights:[_rightsKey]="overviewRequiredRights"
                       v-if="overviewLinkRouteName"
                       :to="{ name: overviewLinkRouteName,params: { id: row.item[overviewLinkKey] }}"
                       class="px-1">
            <b-icon icon="info-square" color="black" class="clickable icon-color"></b-icon>
          </router-link>

          <slot name="custom-edit-action">
            <router-link v-require-all-rights:[_rightsKey]="editRequiredRights"
                v-if="editLinkRouteName" :to="{ name: editLinkRouteName,
                params: { id: row.item[overviewLinkKey] }}" title="Edit" class="px-1">
              <b-icon icon="pencil-square" color="black" class="clickable icon-color"></b-icon>
            </router-link>
          </slot>

          <div class="px-1" v-if="deletable" v-require-all-rights:[_rightsKey]="deleteRequiredRights">
            <b-icon icon="trash" title="Remove" @click="deleteItem(row.item[overviewLinkKey], row.item)"
                    class="clickable icon-color"></b-icon>
          </div>
        </div>
      </template>
      <template #table-busy>
        <div class="text-center my-2" style="color: #5e72e4">
          <b-spinner class="align-middle"></b-spinner>
          <span class="sr-only">Loading</span>
        </div>
      </template>
    </b-table>
    <template #footer>
      <div class="text-muted" style="font-size: 12px" v-if="pageData">
        <strong>Total elements: </strong>{{ pageData.totalElements }} |
        <strong>Total pages: </strong>{{ pageData.totalPages }}
      </div>
    </template>
  </b-card>
</template>

<script>
import '@/assets/styles/table-card.css'
import SingleSearchSelect from "@/components/util/SingleSearchSelect";
import {rightUpdateFunctions} from "@/mixins/util";

export default {
  name: "Table",
  components: {SingleSearchSelect},
  mixins: [rightUpdateFunctions],
  props: {
    title: {
      type: String,
      default: 'Items'
    },
    subTitle: {
      type: String,
      default: ''
    },
    apiUrl: {
      type: String
    },
    overrideApiUrl: {
      type: String,
      default: null
    },
    fields: {
      type: Array
    },
    overviewLinkKey: {
      type: String,
      default: null
    },
    overviewRequiredRights: {
      type: String,
      default: ''
    },
    overviewLinkRouteName: {
      type: String,
      default: null
    },
    editLinkRouteName: {
      type: String,
      default: null
    },
    editRequiredRights: {
      type: String,
      default: ''
    },
    searchable: {
      type: Boolean,
      default: false
    },
    clickableRow: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: false
    },
    filterQueryParamName: {
      type: String,
      default: 'id'
    },
    perPageCustomizable: {
      type: Boolean,
      default: false
    },
    pageOptions: {
      type: Array,
      default: () => [5, 10, 25, 100]
    },
    /**
     * Each action in this array must define a title and a callback method which will be called on click.
     * e.g.
     * {
     *   title: 'test'
     *   callback () => alert('hello')
     * }
     */
    actionButtons: {
      type: Array,
      default: () => []
    },
    filterSearchProvider: {
      type: Function,
      default: () => []
    },
    /**
     * Must be well formatted by itself. Will just be appended to the end of the query.
     *
     * correct: var1=2&var2=bla
     */
    staticQueryParams: {
      type: String,
      default: ''
    },
    /**
     * If the required key is nested this variable will be deconstructed on dots.
     * e.g. 'var1.var2.key' will assume var1 and var2 are objects that contain the following var.
     */
    deleteInfoKey: {
      type: String,
      default: 'name'
    },
    deletable: {
      type: Boolean,
      default: false
    },
    deleteRequiredRights: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isBusy: false,
      currentPage: 1,
      rows: 0,
      filter: null,
      searchQuery: '',
      pageData: null,
      perPage: this.pageOptions[1]
    }
  },
  methods: {
    toggleBusy() {
      this.isBusy = !this.isBusy
    },
    changeFilter(item) {
      console.log(item)
      this.filter = item
      this.$root.$emit('bv::refresh::table', 'data-table')
    },
    provider(ctx, callback) {
      let sort = '&' + this.staticQueryParams
      if (ctx.sortBy !== "") {
        sort = `&sort=${ctx.sortBy},${ctx.sortDesc ? 'desc' : 'asc'}`
      }
      if (ctx.filter !== "") {
        sort = `&q=${ctx.filter}`
      }
      if (this.filter !== null) {
        sort = `&${this.filterQueryParamName}=${this.filter}`
      }
      let apiHost;
      if (this.overrideApiUrl) {
        apiHost = this.overrideApiUrl
      } else {
        apiHost = process.env.VUE_APP_API_URL
      }
      this.$maxios('get', `${apiHost}${this.apiUrl}?page=${ctx.currentPage - 1}&size=${ctx.perPage}${sort}`)
          .then(response => {
            // Pluck the array of items off our axios response
            this.pageData = response.data
            const items = response.data.content
            this.rows = response.data.totalElements
            // Provide the array of items to the callback
            callback(items)
          })
          .catch(() => {
            callback([])
          })

      // Must return null or undefined to signal b-table that callback is being used
      return null
    },
    refresh() {
      this.currentPage = 1
      this.$root.$emit('bv::refresh::table', 'data-table')
    },
    deleteItem(itemKey, item) {
      let name = item;
      for (const part of this.deleteInfoKey.split('.')) {
        const {[part]: temp} = name
        name = temp
      }
      this.$bvModal.msgBoxConfirm(`Delete '${name}' and all its relations?`, {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        cancelVariant: 'secondary',
        okTitle: 'Yes, delete',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(value => {
        if (value) {
          let apiHost;
          if (this.overrideApiUrl) {
            apiHost = this.overrideApiUrl
          } else {
            apiHost = process.env.VUE_APP_API_URL
          }
          this.$maxios('delete', `${apiHost}${this.apiUrl}/${itemKey}`).then(() => {
            this.refresh()
          }).catch(() => {
            this.$alerts.setAlertMessage('An error occurred try again later.', 2, 'danger')
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.dash-card {
  background-color: white;
  padding: 1em;
  border-radius: 8px;
}
</style>