<template>
  <b-modal :id="id"
           @show="resetModal"
           @hidden="resetModal"
           v-model="showModal"
           title="Invite new user">
    <form ref="form" @submit.prevent="handleOk">

      <!-- Username -->
      <b-form-group
          label="Username"
          label-for="username-input"
          invalid-feedback="A valid username is required."
          :state="usernameState"
      >
        <b-form-input
            id="username-input"
            v-model="username"
            :state="usernameState"
            ref="usernameState"
            v-on:change="checkFieldValidity('usernameState')"
        ></b-form-input>
        <small class="muted">If left empty, the user can choose themselves.</small>
      </b-form-group>

      <!-- User email -->
      <b-form-group
          label="Email"
          label-for="email-input"
          invalid-feedback="Please enter a valid email address."
          :state="emailState"
      >
        <b-form-input
            id="username-input"
            v-model="email"
            :state="emailState"
            required
            ref="emailState"
            type="email"
            v-on:change="checkFieldValidity('emailState')"
        ></b-form-input>
      </b-form-group>

      <b-row>
        <!-- Is the user activated? -->
        <b-col cols="4">
          <b-form-checkbox id="activated-input" v-model="activated">Activated</b-form-checkbox>
        </b-col>

        <!-- Is the user locked? -->
        <b-col cols="4">
          <b-form-checkbox id="locked-input" v-model="locked">Locked</b-form-checkbox>
        </b-col>

        <!-- Is the user a super admin? -->
        <b-col cols="4">
          <b-form-checkbox id="admin-input" v-model="admin">Admin</b-form-checkbox>
        </b-col>
      </b-row>

      <!-- General form error. -->
      <b-form-invalid-feedback :state="!formError" v-if="formError">
        Something went wrong.
      </b-form-invalid-feedback>
    </form>
    <template #modal-footer>
      <div class="w-100">
        <b-button v-if="!sending"
                  variant="primary"
                  class="float-right"
                  @click="handleOk"
        >
          Send invite
        </b-button>
        <b-button v-else variant="primary" class="float-right" disabled>
          <b-spinner small></b-spinner>
          <span class="sr-only">Sending...</span>
        </b-button>
        <b-button
            variant="secondary"
            class="float-right mr-2"
            @click="showModal=false"
        >
          Cancel
        </b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
export default {
  name: "CreateUserInviteModal",
  data: function () {
    return {
      id: 'CreateUserInviteModal',
      usernameState: null,
      username: '',
      emailState: null,
      email: '',
      activated: true,
      locked: false,
      admin: false,
      showModal: false,
      formError: false,
      sending: false,
      callback: null
    }
  },
  methods: {
    show(callback) {
      this.showModal = true
      this.callback = callback
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.checkFieldValidity('usernameState')
      this.checkFieldValidity('emailState')
      return valid
    },
    checkFieldValidity(fieldRef) {
      this[fieldRef] = this.$refs[fieldRef].checkValidity()
    },
    resetModal() {
      this.username = ''
      this.usernameState = null
      this.email = ''
      this.emailState = null
      this.activated = true
      this.locked = false
      this.admin = false
      this.formError = false
      this.sending = false
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      let invite;
      if (this.username === '' || this.username === null) {
        invite = {
          email: this.email,
          locked: this.locked,
          admin: this.admin,
          roles: [],
          services: []
        }
      } else {
        invite = {
          username: this.username,
          email: this.email,
          locked: this.locked,
          admin: this.admin,
          roles: [],
          services: []
        }
      }

      this.sending = true
      this.$maxios('post', `${process.env.VUE_APP_AUTH_API_URL}/userInvitations`, invite)
          .then(() => {
            this.sending = false
            this.showModal = false
            this.callback()
          })
          .catch(() => {
            this.formError = true
            this.sending = false
          })
    }
  }
}
</script>

<style scoped>

</style>