<template>
  <div>
    <div class="sub-page-header">
      <div class="container-fluid py-4 d-flex justify-content-between">
        <h3 class="page-title sm">Profile</h3>
      </div>

    </div>
    <div class="container-fluid " style="margin-top: -4.5rem;">
      <b-card>
        <b-container fluid class="py-3 px-3">
          <h4 class="text-capitalize">{{ $store.getters.getUserInfo.username }}</h4>
          <b-row class="profile-row">
            <b-col cols="3" class="my-auto"><strong>Username</strong></b-col>
            <b-col cols="6">
              <b-input :value="$store.getters.getUserInfo.username" disabled></b-input>
            </b-col>
          </b-row>

          <b-row class="profile-row">
            <b-col cols="3" class="my-auto"><strong>Name</strong></b-col>
            <b-col cols="6">
              <b-input v-model="newName" disabled></b-input>
            </b-col>
            <b-col>
              <b-button variant="success" v-if="newName && newName !== $store.getters.getUserInfo.fullName">Save</b-button>
            </b-col>
          </b-row>

          <b-row class="profile-row">
            <b-col cols="3" class="my-auto"><strong>Email</strong></b-col>
            <b-col cols="6">
              <b-input v-model="newEmail" disabled></b-input>
            </b-col>
            <b-col>
              <b-button variant="success" v-if="newEmail && newEmail !== $store.getters.getUserInfo.email">Save</b-button>
            </b-col>
          </b-row>

          <b-row class="profile-row">
            <b-col cols="3" class="my-auto"><strong>New Password</strong></b-col>
            <b-col cols="6">
              <b-input v-model="newPassword"></b-input>
            </b-col>
            <b-col>
              <b-button variant="success" @click="savePassword" v-if="newPassword && newPassword !== ''">Save</b-button>
            </b-col>
          </b-row>
          <b-row><b-col>
            <strong>My Services</strong>
            <ul>
              <li v-for="(service, index) of services" :key="index">
                {{ service.name }}, {{ service.description }}
              </li>
            </ul>
          </b-col></b-row>
        </b-container>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "Profile",
  data() {
    return {
      services: [],
      newPassword: '',
      newEmail: this.$store.getters.getUserInfo.email,
      newName: this.$store.getters.getUserInfo.fullName
    }
  },
  methods: {
    savePassword() {
      this.$maxios('post', `${process.env.VUE_APP_AUTH_API_URL}/users/me/updatePassword`, {
        password: this.newPassword
      }).then(() => {
        this.newPassword = ''
        this.$alerts.setAlertMessage('Password successfully changed.')
      }).catch((err) => {
        if (err.response.data.message) {
          this.$alerts.setAlertMessage(err.response.data.message, 2, 'danger')
        } else {
          this.$alerts.setAlertMessage('Something went wrong with saving the password.', 2, 'danger')
        }
      })
    }
  },
  mounted() {
    this.$maxios('get', `${process.env.VUE_APP_AUTH_API_URL}/users/me/services`).then(response => {
      this.services = response.data
    })
  }
}
</script>

<style scoped>
.profile-row {
  padding: 15px 0;
}
</style>