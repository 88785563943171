<template>
  <div>
    <Table
        title="Services"
        ref="serviceTable"
        :per-page="10"
        :override-api-url="authApiUrl"
        api-url="/services"
        :fields="fields"
        searchable
        overview-link-key="name"
        per-page-customizable
        deletable
    ></Table>
  </div>
</template>

<script>
import Table from "@/components/routes/Table";

export default {
  name: "ServicesTab",
  components: {Table},
  data() {
    return {
      authApiUrl: process.env.VUE_APP_AUTH_API_URL,
      fields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'description',
          sortable: false
        },
        {
          key: 'createdAt',
          sortable: false,
          formatter: this.dateFormat
        }
      ]
    }
  },
  methods: {
    dateFormat(createdAt) {
      return new Date(createdAt).toDateString()
    }
  },
}
</script>

<style scoped>

</style>
