import Profile from "@/components/routes/Profile";
import UserEdit from "@/components/routes/administration/editors/UserEdit";
import administration from "@/components/routes/Administration";
import UsersTab from "@/components/routes/administration/overviews/UsersTab";
import RightsTab from "@/components/routes/administration/overviews/RightsTab";
import RolesTab from "@/components/routes/administration/overviews/RolesTab";
import ServicesTab from "@/components/routes/administration/overviews/ServicesTab";
import RoleEdit from "@/components/routes/administration/editors/RoleEdit";

const routes = [
    {
        path: '/administration',
        name: 'administration',
        component: administration,
        children: [
            {
                path: 'users',
                name: 'administration-users',
                component: UsersTab,
                meta: {
                    requiredRights: 'users_get'
                }
            },
            {
                path: 'services',
                name: 'administration-services',
                component: ServicesTab,
                meta: {
                    requiredRights: 'services_get'
                }
            },
            {
                path: 'rights',
                name: 'administration-rights',
                component: RightsTab,
                meta: {
                    requiredRights: 'rights_get'
                }
            },
            {
                path: 'roles',
                name: 'administration-roles',
                component: RolesTab,
                meta: {
                    requiredRights: 'roles_get'
                }
            }
        ]
    },
    {
        path: '/administration/roles/:id/',
        name: 'edit-role',
        component: RoleEdit,
        meta: {
            requiredRights: 'roles_add_right,roles_remove_right,roles_get_rights'
        }
    },
    {
        path: '/administration/users/:id/edit',
        name: 'edit-users',
        component: UserEdit,
        meta: {
            requiredRights: 'users_add_role,users_add_service,users_get_roles,users_get_services,users_remove_role,users_remove_service'
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile
    }
]

export default routes