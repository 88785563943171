<template>
  <div>
    <Table
        title="Rights"
        :per-page="10"
        :override-api-url="authApiUrl"
        api-url="/rights"
        :fields="fields"
        overview-link-key="name"
        ref="rightTable"
        searchable
        per-page-customizable
        deletable
    ></Table>
    <AddRightModal ref="rightModal"></AddRightModal>
  </div>
</template>

<script>
import Table from "@/components/routes/Table";
import AddRightModal from "@/components/routes/administration/modals/AddRightModal";

export default {
  name: "RightsTab",
  components: {AddRightModal, Table},
  data() {
    return {
      authApiUrl: process.env.VUE_APP_AUTH_API_URL,
      fields: [
        {
          key: 'name'
        },
        {
          key: 'description'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
