<template>
  <div>
    <OverlayWithText :show="saving"></OverlayWithText>
    <div class="sub-page-header">
      <div class="container-fluid py-4 d-flex justify-content-between">
        <h3 class="page-title sm">Edit User</h3>
      </div>
    </div>
    <div class="container-fluid" style="margin-top: -4.5rem;">
      <b-card class="edit-container" v-if="!loading">
        <b-form @submit="onSubmit">
          <b-form-group label="Username" label-for="username-input">
            <b-form-input
                id="username-input"
                v-model="user.username"
                :state="validUsername"
                required></b-form-input>
            <b-form-invalid-feedback :state="validUsername">
              Username cannot be empty.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Full name" label-for="full-name-input">
            <b-form-input
                id="full-name-input"
                v-model="user.fullName"
                :state="validFullName"
                type="text"
                required></b-form-input>
            <b-form-invalid-feedback :state="validFullName">
              Not a valid email.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Email" label-for="email-input">
            <b-form-input
                id="email-input"
                v-model="user.email"
                :state="validEmail"
                type="email"
                required></b-form-input>
            <b-form-invalid-feedback :state="validEmail">
              Not a valid email.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-row>
            <!-- Is the user activated? -->
            <b-col cols="3">
              <b-form-checkbox id="activated-input" v-model="user.activated">Activated</b-form-checkbox>
            </b-col>

            <!-- Is the user locked? -->
            <b-col cols="3">
              <b-form-checkbox id="locked-input" v-model="user.locked">Locked</b-form-checkbox>
            </b-col>

            <!-- Is the user a super admin? -->
            <b-col cols="3">
              <b-form-checkbox id="admin-input" v-model="user.admin">Admin</b-form-checkbox>
            </b-col>
          </b-row>
        </b-form>

        <!-- Saving and reset button. -->
        <div class="d-flex justify-content-end">
          <b-button :disabled="!needSaving" variant="light" size="sm"
                    class="mr-3 hop-btn" @click="reset()">Reset
          </b-button>
          <b-button :disabled="!needSaving" variant="light" size="sm"
                    class="hop-btn" @click="save()">Save
          </b-button>
        </div>
      </b-card>
    </div>

    <!-- Services -->
    <div class="container-fluid mt-4">
      <b-card class="edit-container">
        <div class="d-flex justify-content-between">
          <h4>Services</h4>
          <div class="d-flex">
            <SingleSearchSelect
                class="mr-3"
                ref="searchServices"
                placeholder="Select a service"
                :provider="searchServices"
                @itemChanged="setAddableService"></SingleSearchSelect>
            <b-button variant="primary" @click="addService">Add</b-button>
          </div>
        </div>
        <hr>
        <b-form-group v-for="service of services" :key="service.name">
          <div class="d-flex">
            <span class="my-auto titled-text-overflow mr-2"
                  style="width: 100px; text-transform: capitalize"
                  :title="service.name">
              {{ service.name }}
            </span>
            <span class="my-auto titled-text-overflow"
                  style="max-width: 300px; text-transform: capitalize"
                  :title="service.description">
              {{ service.description }}
            </span>
            <b-icon icon="trash" @click="removeService(service)"
                    class="clickable icon-color ml-auto my-auto"></b-icon>
          </div>
        </b-form-group>
      </b-card>
    </div>

    <!-- Roles -->
    <div class="container-fluid mt-4">
      <b-card class="edit-container">
        <div class="d-flex justify-content-between">
          <h4>Roles</h4>
          <div class="d-flex">
            <SingleSearchSelect
                class="mr-3"
                ref="searchRoles"
                placeholder="Select a role"
                :provider="searchRoles"
                @itemChanged="setAddableRole"></SingleSearchSelect>
            <b-button variant="primary" class="" @click="addRole">Add</b-button>
          </div>
        </div>
        <hr>
        <b-form-group v-for="role of roles" :key="role.name">
          <div class="d-flex">
            <span class="my-auto" style="width: 300px">{{ role.name }}</span>
            <b-icon icon="trash" @click="removeRole(role)"
                    class="clickable icon-color ml-auto my-auto"></b-icon>
          </div>
        </b-form-group>
      </b-card>
    </div>
  </div>
</template>

<script>
import OverlayWithText from "@/components/util/OverlayWithText";
import {validateEmail} from "@/mixins/util";
import SingleSearchSelect from "@/components/util/SingleSearchSelect";

export default {
  name: "UserEdit",
  components: {
    SingleSearchSelect,
    OverlayWithText
  },
  data() {
    return {
      saving: false,
      loading: true,
      user: {},
      roles: [],
      services: [],
      userCopy: '',
      authApiUrl: process.env.VUE_APP_AUTH_API_URL,
      addableService: '',
      addableRole: '',
      userUrl: `${process.env.VUE_APP_AUTH_API_URL}/users/${this.$route.params.id}`
    }
  },
  mounted() {
    this.$maxios('get', this.userUrl).then((response) => {
      this.user = response.data
      this.userCopy = JSON.stringify(response.data)
      this.loading = false
    }).catch(() => {
      this.$alerts.setAlertMessage('The user could not be retrieved.', 2, 'danger')
    })
    this.retrieveServices()
    this.retrieveRoles()
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
    },
    retrieveServices() {
      this.$maxios('get', this.userUrl + '/services').then((response) => {
        this.services = response.data
      }).catch(() => {
        this.$alerts.setAlertMessage('The user could not be retrieved.', 2, 'danger')
      })
    },
    retrieveRoles() {
      this.$maxios('get', this.userUrl + '/roles').then((response) => {
        this.roles = response.data
      }).catch(() => {
        this.$alerts.setAlertMessage('The user could not be retrieved.', 2, 'danger')
      })
    },
    searchServices(term, callback) {
      const url = `${this.authApiUrl}/services?q=${term}`
      this.$maxios('get', url).then(response => {
        const items = response.data.content.map(i => {
          return {text: i.name, value: i.name}
        })
        callback(items)
      })
    },
    searchRoles(term, callback) {
      const url = `${this.authApiUrl}/roles?q=${term}`
      this.$maxios('get', url).then(response => {
        const items = response.data.content.map(i => {
          return {text: i.name, value: i.name}
        })
        callback(items)
      })
    },
    reset() {
      this.user = JSON.parse(this.userCopy)
    },
    save() {
      this.saving = true
      // We need to find only the fields that actually changed and send those.
      const oldUser = JSON.parse(this.userCopy)
      const changedKeys = Object
          .keys(oldUser)
          .filter((key) => oldUser[key] !== this.user[key])
      const changes = {}
      for (const key of changedKeys) {
        changes[key] = this.user[key]
      }
      this.$maxios('patch', this.userUrl, changes).then(() => {
        this.saving = false
        this.$alerts.setAlertMessage('The user was saved successfully!', 2, 'success')
        this.$router.replace({name: 'administration-users'})
      }).catch((err) => {
        this.saving = false
        this.$alerts.setAlertMessage(err.response.data.message, 2, 'danger')
      })
    },
    addRole() {
      if (this.addableRole !== '') {
        this.$maxios('post', `${this.userUrl}/roles`, {
          name: this.addableRole
        }).then(() => {
          this.retrieveRoles()
          this.$refs.searchRoles.reset()
          this.addableRole = ''
        }).catch(() => {
          this.$alerts.setAlertMessage(`An error occurred trying to give '${this.addableService}' to the '${this.user.username}'.`,
              2, 'danger')
        })
      }
    },
    removeRole(role) {
      this.$maxios('delete', `${this.userUrl}/roles/${role.name}`)
          .then(() => {
            this.retrieveRoles()
          })
          .catch(() => {
            this.$alerts.setAlertMessage(`An error occurred while removing the user from ${this.addableService}.`,
                2, 'danger')
          })
    },
    addService() {
      if (this.addableService !== '') {
        this.$maxios('post', `${this.userUrl}/services`, {
          serviceName: this.addableService
        }).then(() => {
          this.retrieveServices()
          this.$refs.searchServices.reset()
          this.addableService = ''
        }).catch(() => {
          this.$alerts.setAlertMessage(
              `An error occurred trying to add '${this.user.username}' to '${this.addableService}'.`,
              2, 'danger'
          )
        })
      }
    },
    removeService(service) {
      this.$maxios('delete', `${this.userUrl}/services/${service.name}`)
          .then(() => {
            this.retrieveServices()
          })
          .catch(() => {
            this.$alerts.setAlertMessage(`An error occurred while removing the user from ${this.addableService}.`,
                2, 'danger')
          })
    },
    setAddableRole(role) {
      this.addableRole = role
    },
    setAddableService(service) {
      this.addableService = service
    }
  },
  computed: {
    validUsername: function () {
      return this.user && this.user.username !== null && this.user.username.length > 0 &&
          this.user.username !== ''
    },
    validEmail: function () {
      return validateEmail(this.user.email)
    },
    validFullName: function () {
      return this.user && this.user.fullName !== null && this.user.fullName.length > 0 &&
          this.user.fullName !== ''
    },
    needSaving: function () {
      return JSON.stringify(this.user) !== this.userCopy
    },
  }
}
</script>

<style scoped>
.edit-container {
  padding: 0.75rem 1.25rem;
  color: #222222;
}

.selectable-list-item:hover {
  background: #f3f5f6;
  user-select: none;
}

.clean-search {
  width: 100%;
}

.clean-search, .clean-search:focus, .clean-search:hover {
  border: none !important;
}

.titled-text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>