<template>
  <div class="pb-4">
    <OverlayWithText :show="saving"></OverlayWithText>
    <div class="sub-page-header">
      <div class="container-fluid py-4 d-flex justify-content-between">
        <h3 class="page-title sm">Edit Item Type</h3>
        <div>
          <b-button :disabled="!needSaving" variant="light" size="sm"
                    class="mr-3 hop-btn" @click="reset()">Reset
          </b-button>
          <b-button :disabled="!needSaving" variant="light" size="sm" type="submit"
                    class="hop-btn" form="itemType">Save
          </b-button>
        </div>
      </div>
    </div>
    <div class="container-fluid" style="margin-top: -4.5rem;">
      <b-card class="edit-container" v-if="!loading">
        <h4>General</h4>
        <b-form @submit="save" id="itemType">
          <b-form-group label="Name" label-for="name-input">
            <b-form-input
                id="name-input"
                v-model="itemType.name"
                :state="validName"
                required></b-form-input>
            <b-form-invalid-feedback :state="validName">
              Name cannot be empty.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Description" label-for="description-input">
            <b-form-input
                id="description-input"
                v-model="itemType.description"
                :state="validDescription"
                required></b-form-input>
            <b-form-invalid-feedback :state="validDescription">
              Name cannot be empty.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Consumable" label-for="consumable-input">
            <b-form-checkbox
                id="consumable-input"
                v-model="itemType.consumable"
                required></b-form-checkbox>
            <b-form-text id="password-help-block" v-if="itemType.consumable">
              This item type will be consumed (meaning removed from the warehouse) upon usage. E.g. a screw that has been used in a new item.
            </b-form-text>
            <b-form-text id="password-help-block" v-else>
              This item type will not be consumed upon usage. E.g. tools.
            </b-form-text>
          </b-form-group>

          <b-form-group label="Tags">
            <MultipleSearchSelect
                ref="tagSearchSelect"
                :parent-items="this.itemType.tags.map(i => { return { value: i, text: i } })"
                :provider="(a, b) => search(a, b, 'tags')"
                @itemsChanged="tagsChanged"
            ></MultipleSearchSelect>
            <b-form-text id="password-help-block">
              Searchable tags for this item type.
            </b-form-text>
          </b-form-group>

          <b-form-group label="Categories">
            <MultipleSearchSelect
                ref="categorySearchSelect"
                :parent-items="formattedCategories"
                :provider="(a, b) => search(a, b, 'itemTypeCategories')"
                @itemsChanged="categoriesChanged"
            ></MultipleSearchSelect>
            <b-form-text id="password-help-block">
              Adding and removing these will directly impact attributes and their values.
            </b-form-text>
          </b-form-group>

          <b-form-group label="Attributes" v-if="itemType.attributeValues.length > 0">
            <b-row v-for="attributeValue of itemType.attributeValues" :key="attributeValue.id">
              <b-col cols="7">
                <b-form-group :label="attributeValue.attribute.name"
                              :description="'Please provide the following attribute type: ' + attributeValue.attribute.contentType + '.'">
                  <template v-if="attributeValue.attribute.contentType === 'number'">
                    <b-form-input
                        :id="attributeValue.attribute.name + attributeValue.attribute.id"
                        v-model="attributeValue.value"
                        number
                        :required="attributeValue.required"></b-form-input>
                  </template>
                  <template v-if="attributeValue.attribute.contentType === 'text'">
                    <b-form-input
                        :id="attributeValue.attribute.name + attributeValue.attribute.id"
                        v-model="attributeValue.value"
                        :required="attributeValue.required"></b-form-input>
                  </template>
                  <template v-if="attributeValue.attribute.contentType === 'link'">
                    <b-form-input
                        :id="attributeValue.attribute.name + attributeValue.attribute.id"
                        v-model="attributeValue.value"
                        type="url"
                        :required="attributeValue.required"></b-form-input>
                  </template>
                  <template v-if="attributeValue.attribute.contentType === 'image'">
                    <!-- Add on change here -->
                    <b-form-file
                        @change="parseFileAndSetAttributeData(attributeValue)"
                        accept="image/gif, image/png, image/jpeg, image/webp, image/svg+xml, image/tiff"
                        :id="attributeValue.attribute.name + attributeValue.attribute.id"
                        v-model="attributeValue.rawImageFile"
                        :required="!attributeValue.value && attributeValue.required"
                    ></b-form-file>
                  </template>
                  <template v-if="attributeValue.attribute.contentType === 'document'">
                    <!-- Add on change here -->
                    <b-form-file
                        @change="parseFileAndSetAttributeData(attributeValue)"
                        accept="application/pdf"
                        :id="attributeValue.attribute.name + attributeValue.attribute.id"
                        v-model="attributeValue.rawImageFile"
                        :required="!attributeValue.value && attributeValue.required"
                    ></b-form-file>
                  </template>
                </b-form-group>
              </b-col>
              <b-col cols="3"
                     v-if="attributeValue.value && attributeValue.attribute.contentType === 'image'">
                <b-form-group label="Preview" id="attributeValue.value">
                  <img :src="attributeValue.previewResult" height="38" alt="Image preview...">
                </b-form-group>
              </b-col>
            </b-row>
          </b-form-group>

          <hr>
          <div class="d-flex justify-content-between">
            <h4>Required Parts</h4>
            <div class="d-flex">
              <SingleSearchSelect
                  class="mr-3"
                  ref="searchSubItems"
                  placeholder="Select an item type"
                  :provider="searchItemTypes"
                  @itemChanged="requiredChildTypeChanged"></SingleSearchSelect>
              <b-button variant="primary" class="" @click="addCurrentRequiredType">Add</b-button>
            </div>
          </div>
          <b-form-group v-for="(child, index) of itemType.requiredChildTypes" :key="child.itemTypeId">
            <div class="d-flex">
              <b-input type="number" @change="ev => child.count = parseInt(ev)" min="1" step="1"
                       style="max-width: 75px" size="sm" placeholder="Quantity" :value="child.count">
              </b-input>
              <span class="my-auto ml-2" style="width: 300px">x {{ child.itemTypeName }}</span>
              <b-icon icon="trash" @click="deleteRequiredItemTypeByIndex(index)"
                      class="clickable icon-color ml-auto my-auto"></b-icon>
            </div>
          </b-form-group>

          <hr>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>

import '@/assets/styles/table-card.css'
import MultipleSearchSelect from "@/components/util/MultipleSearchSelect";
import OverlayWithText from "@/components/util/OverlayWithText";
import SingleSearchSelect from "@/components/util/SingleSearchSelect";

export default {
  name: "ItemTypeEdit",
  components: {SingleSearchSelect, OverlayWithText, MultipleSearchSelect},
  data: function () {
    return {
      itemType: {},
      itemTypeCopy: '',
      currentRequiredItemType: '',
      loading: true,
      saving: false,
      src: ''
    }
  },
  mounted() {
    this.$maxios('get', 'itemTypes/' + this.$route.params.id).then(async (response) => {
      this.itemType = response.data
      this.itemTypeCopy = JSON.stringify(response.data)
      await this.parseAttributesFromFirstRequest()
      this.loading = false
    }).catch(() => {
      this.$alerts.setAlertMessage('The itemType could not be retrieved.', 2, 'danger')
    })
  },
  methods: {
    search(term, callback, path) {
      this.$maxios('get', `${path}?q=${term}`).then(response => {
        const items = response.data.content.map(i => {
          return {value: i.name, text: i.name}
        })
        callback(items)
      })
    },
    searchItemTypes(term, callback) {
      this.$maxios('get', `itemTypes?q=${term}`).then(response => {
        const items = response.data.content.filter(i => {
          for (const itemType of this.itemType.requiredChildTypes) {
            if (itemType.itemTypeId === i.id) {
              return false
            }
          }
          return i.id !== this.itemType.id
        }).map(i => {
          return {value: i, text: i.name}
        })
        callback(items)
      })
    },
    searchAttributes(term, callback) {
      this.$maxios('get', `itemTypeAttributes?q=${term}`).then(response => {
        const items = response.data.content.map(i => {
          return {value: i, text: i.name}
        })
        callback(items)
      })
    },
    tagsChanged(items) {
      this.itemType.tags = items.map(i => i.value)
    },
    addCurrentRequiredType() {
      if (this.currentRequiredItemType === '') {
        return
      }
      this.itemType.requiredChildTypes.push({
        count: 1,
        itemTypeId: this.currentRequiredItemType.id,
        itemTypeName: this.currentRequiredItemType.name
      })
      this.$refs.searchSubItems.reset()
      this.currentRequiredItemType = ''
    },
    deleteRequiredItemTypeByIndex(index) {
      this.itemType.requiredChildTypes.splice(index, 1)
    },
    requiredChildTypeChanged(item) {
      this.currentRequiredItemType = item
    },
    categoriesChanged(items) {
      this.itemType.categories = items.map(i => i.value)
      this.updateAttributes()
    },
    async parseAttributesFromFirstRequest() {
      for (const attributeValue of this.itemType.attributeValues) {
        if (attributeValue.value !== null && attributeValue.value.mimeType) {
          let config = {headers: {Accept: `application/json`}}
          await this.$maxios('get', `/files/${attributeValue.value.id}`, config).then(imageResponse => {
            attributeValue.value.base64EncodedContent = imageResponse.data.base64EncodedContent
            attributeValue.rawImageFile = new File([], attributeValue.attribute.name)
            attributeValue.previewResult = 'data:'
                + attributeValue.value.mimeType + ';base64,' + attributeValue.value.base64EncodedContent
          })
        }
      }
    },
    updateAttributes() {
      if (this.itemType.categories.length === 0) {
        this.itemType.attributeValues = []
        return
      }
      this.$maxios('get', `itemTypeAttributes?ignorePagination=true&categories=${this.itemType.categories.join(',')}`).then(response => {
        const newAttributesValues = []
        for (const attribute of response.data.content) {
          let value = null
          for (const attributeValue of this.itemType.attributeValues) {
            if (attribute.id === attributeValue.attribute.id) {
              value = attributeValue.value
            }
          }
          newAttributesValues.push({
            attribute: {
              id: attribute.id,
              name: attribute.name,
              contentType: attribute.contentType,
            },
            value: value
          })
        }
        this.itemType.attributeValues = newAttributesValues
      }).catch(() => {
        this.$alerts.setAlertMessage(
            'An error occurred updating the attributes.', 2, 'danger')
      })
    },
    parseFileAndSetAttributeData(attributeValue) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        // convert image file to base64 string
        attributeValue.value = {
          name: attributeValue.rawImageFile.name,
          mimeType: attributeValue.rawImageFile.type,
          base64EncodedContent: reader.result.split(',')[1]
        };
        attributeValue.previewResult = reader.result
      }, false);
      setTimeout(() => {
        if (attributeValue.rawImageFile) {
          reader.readAsDataURL(attributeValue.rawImageFile);
        }
      }, 100)
    },
    reset() {
      this.itemType = JSON.parse(this.itemTypeCopy)
      this.$refs.tagSearchSelect.resetItems()
      this.$refs.categorySearchSelect.resetItems()
    },
    save(event) {
      event.preventDefault()
      this.saving = true
      const id = this.$route.params.id
      this.$maxios('patch', `itemTypes/${id}`, this.itemType).then(() => {
        this.$router.replace({name: 'overview-item-type', params: {id: id}})
      }).catch(() => {
        this.saving = false
        this.$alerts.setAlertMessage('An error occurred try again later.', 2, 'danger')
      })
    }
  },
  computed: {
    validName: function () {
      return this.itemType && this.itemType.name !== null && this.itemType.name.length > 0 &&
          this.itemType.name !== ''
    },
    validDescription: function () {
      return this.itemType && this.itemType.description !== null && this.itemType.description.length > 0 &&
          this.itemType.description !== ''
    },
    needSaving: function () {
      return JSON.stringify(this.itemType) !== this.itemTypeCopy
    },
    formattedCategories: function () {
      return this.itemType.categories.map(i => {
        return {value: i, text: i}
      })
    }
  }
}
</script>

<style scoped>
.edit-container {
  padding: 0.75rem 1.25rem;
  color: #222222;
}

</style>